'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var common = require('../../common.js');

var _a, _b;
exports.RecentActivityFeedType = void 0;
(function (RecentActivityFeedType) {
  RecentActivityFeedType["All"] = "all";
  RecentActivityFeedType["Email"] = "email";
  RecentActivityFeedType["Comment"] = "comment";
  RecentActivityFeedType["Task"] = "task";
  RecentActivityFeedType["Meeting"] = "meeting";
  RecentActivityFeedType["LogEmail"] = "log_email";
  RecentActivityFeedType["LogCall"] = "log_call";
  RecentActivityFeedType["History"] = "history";
  RecentActivityFeedType["Opportunity"] = "opportunity";
  RecentActivityFeedType["SyncEntityWithPSA"] = "sync_entity_with_psa";
})(exports.RecentActivityFeedType || (exports.RecentActivityFeedType = {}));
exports.AuditTypes = void 0;
(function (AuditTypes) {
  AuditTypes["Create"] = "create";
  AuditTypes["Update"] = "update";
  AuditTypes["Delete"] = "delete";
  AuditTypes["ChangeSuggestionAccepted"] = "change_suggestion_accepted";
  AuditTypes["ChangeSuggestionAutoAccepted"] = "change_suggestion_auto_accepted";
  AuditTypes["ChangeSuggestionRejected"] = "change_suggestion_rejected";
  AuditTypes["Shared"] = "shared";
  AuditTypes["SharedRequested"] = "shared_requested";
  AuditTypes["SharedAccepted"] = "shared_accepted";
  AuditTypes["SharedRejected"] = "shared_rejected";
  AuditTypes["Unshared"] = "unshared";
  AuditTypes["Pushed"] = "pushed";
  AuditTypes["Pulled"] = "pulled";
  AuditTypes["Failed"] = "failed";
})(exports.AuditTypes || (exports.AuditTypes = {}));
exports.SyncStatus = void 0;
(function (SyncStatus) {
  SyncStatus["Success"] = "success";
  SyncStatus["Failed"] = "failed";
})(exports.SyncStatus || (exports.SyncStatus = {}));
exports.AuditSyncSource = void 0;
(function (AuditSyncSource) {
  AuditSyncSource["ConnectWise"] = "connectwise";
  AuditSyncSource["Zapier"] = "zapier";
  AuditSyncSource["AutoTask"] = "autotask";
  AuditSyncSource["Nylas"] = "nylas";
  AuditSyncSource["Etilize"] = "etilize";
  AuditSyncSource["DataImport"] = "data_import";
  AuditSyncSource["DellQuoteImport"] = "dell_quote_import";
  AuditSyncSource["Kaseya"] = "kaseya";
  AuditSyncSource["Ingram"] = "ingram";
  AuditSyncSource["TechData"] = "tech_data";
  AuditSyncSource["DAndH"] = "d_and_h";
  AuditSyncSource["Synnex"] = "synnex";
  AuditSyncSource["Syncro"] = "syncro";
  AuditSyncSource["Infusionsoft"] = "infusionsoft";
  AuditSyncSource["Hubspot"] = "hubspot";
  AuditSyncSource["CustomDistributor"] = "custom_distributor";
  AuditSyncSource["HaloPSA"] = "haloPSA";
})(exports.AuditSyncSource || (exports.AuditSyncSource = {}));
var MarketingSyncSource = [exports.AuditSyncSource.Infusionsoft, exports.AuditSyncSource.Hubspot];
var SyncEntityRequest = /** @class */function () {
  function SyncEntityRequest() {
    this.entity_type = "";
    this.entity_id = "";
    this.sync_source = null;
    this.sync_source_id = null;
    this.should_export = null;
  }
  return SyncEntityRequest;
}();
var AuditSyncSourceOptions = (_a = {}, _a[exports.AuditSyncSource.ConnectWise] = "ConnectWise PSA", _a[exports.AuditSyncSource.Zapier] = "Zapier", _a[exports.AuditSyncSource.AutoTask] = "Autotask", _a[exports.AuditSyncSource.Nylas] = "Nylas", _a[exports.AuditSyncSource.Etilize] = "Etilize", _a[exports.AuditSyncSource.DataImport] = "Data Import", _a[exports.AuditSyncSource.DellQuoteImport] = "Dell Quote Import", _a[exports.AuditSyncSource.Kaseya] = "Kaseya BMS", _a[exports.AuditSyncSource.Ingram] = "Ingram", _a[exports.AuditSyncSource.TechData] = "Tech Data", _a[exports.AuditSyncSource.DAndH] = "D and H", _a[exports.AuditSyncSource.Synnex] = "Synnex", _a[exports.AuditSyncSource.Syncro] = "Syncro", _a[exports.AuditSyncSource.Infusionsoft] = "Infusionsoft", _a[exports.AuditSyncSource.Hubspot] = "HubSpot", _a[exports.AuditSyncSource.CustomDistributor] = "Custom Distributor", _a[exports.AuditSyncSource.HaloPSA] = "HaloPSA", _a);
var AuditEntityNameOptions = (_b = {}, _b[common.ZomentumEntities.Client] = "Client Company", _b[common.ZomentumEntities.Products] = "Products", _b[common.ZomentumEntities.Document] = "Document", _b[common.ZomentumEntities.DocumentBlock] = "Document Block", _b[common.ZomentumEntities.Comment] = "Note", _b[common.ZomentumEntities.Activities] = "Activities", _b[common.ZomentumEntities.Opportunity] = "Opportunity", _b[common.ZomentumEntities.Product] = "Product", _b[common.ZomentumEntities.ClientContact] = "Client Contact", _b[common.ZomentumEntities.SalesTask] = "Task", _b[common.ZomentumEntities.SalesMeeting] = "Meeting", _b[common.ZomentumEntities.Email] = "Email", _b[common.ZomentumEntities.EmailThread] = "Email Thread", _b[common.ZomentumEntities.EmailMessage] = "Email Message", _b[common.ZomentumEntities.SalesLogEmail] = "Email Log", _b[common.ZomentumEntities.SalesLogCall] = "Call Log", _b[common.ZomentumEntities.Assessment] = "Assessment", _b[common.ZomentumEntities.Qbr] = "QBR", _b[common.ZomentumEntities.CustomField] = "Custom Field", _b[common.ZomentumEntities.SalesActivity] = "Sales Activities", _b[common.ZomentumEntities.User] = "User", _b[common.ZomentumEntities.Pricing] = "Pricing", _b[common.ZomentumEntities.VendorResource] = "Vendor Resource", _b[common.ZomentumEntities.VendorPartner] = "Partner", _b[common.ZomentumEntities.VendorCompany] = "Vendor Company", _b[common.ZomentumEntities.MarketplaceListing] = "Marketplace Listing", _b[common.ZomentumEntities.VendorPartner] = "Partner", _b[common.ZomentumEntities.Vendors] = "Vendors", _b[common.ZomentumEntities.VendorCompanies] = "Companies", _b[common.ZomentumEntities.VendorTemplate] = "Template", _b[common.ZomentumEntities.VendorOpportunity] = "Opportunity", _b[common.ZomentumEntities.MarketplaceSKU] = "SKU", _b[common.ZomentumEntities.VendorClient] = "Vendor Client", _b[common.ZomentumEntities.SalesAutomation] = "Sales Automation", _b[common.ZomentumEntities.Supplier] = "Supplier", _b);
exports.ActivityAuthorType = void 0;
(function (ActivityAuthorType) {
  ActivityAuthorType["User"] = "user";
  ActivityAuthorType["ClientUser"] = "client_user";
  ActivityAuthorType["Admin"] = "admin";
  ActivityAuthorType["Unknown"] = "unknown";
})(exports.ActivityAuthorType || (exports.ActivityAuthorType = {}));
exports.ActivityType = void 0;
(function (ActivityType) {
  ActivityType["Created"] = "created";
  ActivityType["Edited"] = "edited";
  ActivityType["Sent"] = "sent";
  ActivityType["Opened"] = "opened";
  ActivityType["Commented"] = "commented";
  ActivityType["Accepted"] = "accepted";
  ActivityType["SignInProgress"] = "sign_in_progress";
  ActivityType["LinkChanged"] = "link_changed";
  ActivityType["Signed"] = "signed";
  ActivityType["Won"] = "won";
  ActivityType["Lost"] = "lost";
})(exports.ActivityType || (exports.ActivityType = {}));
var ActivityAuthor = /** @class */function () {
  function ActivityAuthor() {
    this.name = new common.Name();
  }
  return ActivityAuthor;
}();
exports.ActivityMessageType = void 0;
(function (ActivityMessageType) {
  ActivityMessageType["TEXT"] = "plain_text";
  ActivityMessageType["REDIRECT"] = "redirect_in_app";
})(exports.ActivityMessageType || (exports.ActivityMessageType = {}));
var Activity = /** @class */function () {
  function Activity() {
    this.activity_type = exports.ActivityType.Created;
    this.author = new ActivityAuthor();
    this.author_id = "";
    this.author_type = exports.ActivityAuthorType.User;
    this.created = new Date();
    this.entity_id = "";
    this.entity_type = null;
    this.id = "";
    this.message = new Array();
    this.updated = new Date();
    this.user_ip_address = "";
  }
  return Activity;
}();
var CommentAuthor = /** @class */function () {
  function CommentAuthor() {
    this.id = "";
    this.created = new Date();
    this.updated = new Date();
    this.email = "";
    this.name = new common.Name();
    this.company_id = "";
  }
  return CommentAuthor;
}();
var Comment = /** @class */function () {
  function Comment() {
    this.author_id = "";
    this.author_name = new common.Name();
    this.body = "";
    this.created = new Date();
    this.deleted = false;
    this.entity_id = "";
    this.entity_type = null;
    this.id = "";
    this.in_reply_to = "";
    this.last_message_time_stamp = new Date();
    this.pinned = false;
    this.type = "test";
    this.updated = new Date();
    this.author = new CommentAuthor();
    this.attachments = [];
  }
  return Comment;
}();
var AddRecentActivityFeedCommentRequest = /** @class */function () {
  function AddRecentActivityFeedCommentRequest(body, entity_id, entity_type) {
    this.body = "";
    this.entity_id = "";
    this.entity_type = null;
    this.body = body;
    this.entity_id = entity_id;
    this.entity_type = entity_type;
  }
  return AddRecentActivityFeedCommentRequest;
}();
var RecentActivityFeedCommentFormValues = /** @class */function () {
  function RecentActivityFeedCommentFormValues() {
    this.body = null;
  }
  return RecentActivityFeedCommentFormValues;
}();

exports.Activity = Activity;
exports.ActivityAuthor = ActivityAuthor;
exports.AddRecentActivityFeedCommentRequest = AddRecentActivityFeedCommentRequest;
exports.AuditEntityNameOptions = AuditEntityNameOptions;
exports.AuditSyncSourceOptions = AuditSyncSourceOptions;
exports.Comment = Comment;
exports.CommentAuthor = CommentAuthor;
exports.MarketingSyncSource = MarketingSyncSource;
exports.RecentActivityFeedCommentFormValues = RecentActivityFeedCommentFormValues;
exports.SyncEntityRequest = SyncEntityRequest;
