'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../node_modules/tslib/tslib.es6.mjs.js');
var common = require('../common.js');
var index = require('../Products/index.js');

var _a, _b, _c, _d, _e;
var _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
exports.DocumentProcessorProviders = void 0;
(function (DocumentProcessorProviders) {
  DocumentProcessorProviders["GoogleDrive"] = "googledrive";
})(exports.DocumentProcessorProviders || (exports.DocumentProcessorProviders = {}));
exports.IntegrationProviders = void 0;
(function (IntegrationProviders) {
  IntegrationProviders["ConnectWise"] = "connectwise";
  IntegrationProviders["Email"] = "email";
  IntegrationProviders["Autotask"] = "autotask";
})(exports.IntegrationProviders || (exports.IntegrationProviders = {}));
exports.SyncSource = void 0;
(function (SyncSource) {
  SyncSource["ConnectWise"] = "connectwise";
  SyncSource["Autotask"] = "autotask";
  SyncSource["Zapier"] = "zapier";
  SyncSource["Kaseya"] = "kaseya";
  SyncSource["Syncro"] = "syncro";
  SyncSource["InfusionSoft"] = "infusion_soft";
  SyncSource["Hubspot"] = "hubspot";
  SyncSource["RepairShopr"] = "repairshopr";
  SyncSource["Halo"] = "halo";
})(exports.SyncSource || (exports.SyncSource = {}));
var PSAList = [{
  label: "Autotask",
  value: exports.SyncSource.Autotask
}, {
  label: "ConnectWise PSA",
  value: exports.SyncSource.ConnectWise
}, {
  label: "Kaseya BMS",
  value: exports.SyncSource.Kaseya
}, {
  label: "Syncro",
  value: exports.SyncSource.Syncro
}, {
  label: "RepairShopr",
  value: exports.SyncSource.RepairShopr
}, {
  label: "Halo",
  value: exports.SyncSource.Halo
}];
var timezones = [{
  name: "UTC",
  offset: 0
}, {
  name: "UTC +00:00 Canary, Faeroe, Faroe, and Madeira Time",
  offset: 0
}, {
  name: "UTC +00:00 Danmarkshavn Time",
  offset: 0
}, {
  name: "UTC +00:00 Dublin, London, and Lisbon Time",
  offset: 0
}, {
  name: "UTC +00:00 Greenwich Mean Time",
  offset: 0
}, {
  name: "UTC +00:00 Reykjavik and St Helena Time",
  offset: 0
}, {
  name: "UTC +00:00 Troll Time",
  offset: 0
}, {
  name: "UTC +01:00 Casablanca and El Aaiun Time",
  offset: -60
}, {
  name: "UTC +01:00 Central European Time",
  offset: -60
}, {
  name: "UTC +01:00 Ceuta Time",
  offset: -60
}, {
  name: "UTC +01:00 Jan Mayen Time",
  offset: -60
}, {
  name: "UTC +01:00 Longyearbyen Time",
  offset: -60
}, {
  name: "UTC +02:00 Amman Time",
  offset: -120
}, {
  name: "UTC +02:00 Beirut Time",
  offset: -120
}, {
  name: "UTC +02:00 Chisinau and Tiraspol Time",
  offset: -120
}, {
  name: "UTC +02:00 Damascus Time",
  offset: -120
}, {
  name: "UTC +02:00 Eastern European Time",
  offset: -120
}, {
  name: "UTC +02:00 Famagusta and Nicosia Time",
  offset: -120
}, {
  name: "UTC +02:00 Gaza and Hebron Time",
  offset: -120
}, {
  name: "UTC +02:00 Jerusalem and Tel Aviv Time",
  offset: -120
}, {
  name: "UTC +02:00 Kaliningrad Time",
  offset: -120
}, {
  name: "UTC +02:00 South Africa Time",
  offset: -120
}, {
  name: "UTC +03:00 Aden, Baghdad, Bahrain, Istanbul, Kuwait, Qatar, and Riyadh Time",
  offset: -180
}, {
  name: "UTC +03:00 Antananarivo, Comoro, and Mayotte Time",
  offset: -180
}, {
  name: "UTC +03:00 East Africa Time",
  offset: -180
}, {
  name: "UTC +03:00 Istanbul, Kirov, Minsk, Moscow, and Simferopol Time",
  offset: -180
}, {
  name: "UTC +03:00 Syowa Time",
  offset: -180
}, {
  name: "UTC +03:30 Tehran Time",
  offset: -210
}, {
  name: "UTC +04:00 Astrakhan, Samara, Saratov, Ulyanovsk, and Volgograd Time",
  offset: -240
}, {
  name: "UTC +04:00 Baku, Dubai, Muscat, Tbilisi, and Yerevan Time",
  offset: -240
}, {
  name: "UTC +04:00 Mahe, Mauritius, and Reunion Time",
  offset: -240
}, {
  name: "UTC +04:30 Kabul Time",
  offset: -270
}, {
  name: "UTC +05:00 Aqtau, Aqtobe, Ashgabat, Ashkhabad, Atyrau, Dushanbe, Karachi, Oral, Qyzylorda, Samarkand, Tashkent, and Yekaterinburg Time",
  offset: -300
}, {
  name: "UTC +05:00 Kerguelen and Maldives Time",
  offset: -300
}, {
  name: "UTC +05:00 Mawson Time",
  offset: -300
}, {
  name: "UTC +05:30 New Delhi, Mumbai, and Calcutta Time",
  offset: -330
}, {
  name: "UTC +05:45 Kathmandu and Katmandu Time",
  offset: -345
}, {
  name: "UTC +06:00 Almaty, Bishkek, Dacca, Dhaka, Kashgar, Omsk, Kostanay, Thimbu, Thimphu, and Urumqi Time",
  offset: -360
}, {
  name: "UTC +06:00 Chagos Time",
  offset: -360
}, {
  name: "UTC +06:00 Vostok Time",
  offset: -360
}, {
  name: "UTC +06:30 Cocos Time",
  offset: -390
}, {
  name: "UTC +06:30 Rangoon and Yangon Time",
  offset: -390
}, {
  name: "UTC +07:00 Christmas Time",
  offset: -420
}, {
  name: "UTC +07:00 Davis Time",
  offset: -420
}, {
  name: "UTC +07:00 Indochina Time",
  offset: -420
}, {
  name: "UTC +08:00 Casey Time",
  offset: -480
}, {
  name: "UTC +08:00 China, Hong Kong, and Singapore Time",
  offset: -480
}, {
  name: "UTC +08:00 Perth and West Time",
  offset: -480
}, {
  name: "UTC +08:45 Eucla Time",
  offset: -525
}, {
  name: "UTC +09:00 Palau Time",
  offset: -540
}, {
  name: "UTC +09:00 Tokyo, Seoul, Chita, Dili, Jayapura, Khandyga, Pyongyang, and Yakutsk Time",
  offset: -540
}, {
  name: "UTC +09:30 Darwin and North Time",
  offset: -570
}, {
  name: "UTC +10:00 Brisbane, Lindeman, and Queensland Time",
  offset: -600
}, {
  name: "UTC +10:00 Chuuk, Guam, Port Moresby, Saipan, Truk, and Yap Time",
  offset: -600
}, {
  name: "UTC +10:00 DumontDUrville Time",
  offset: -600
}, {
  name: "UTC +10:00 Ust-Nera and Vladivostok Time",
  offset: -600
}, {
  name: "UTC +10:30 Adelaide, Broken Hill, South, and Yancowinna Time",
  offset: -630
}, {
  name: "UTC +11:00 Australian Eastern Daylight Time",
  offset: -660
}, {
  name: "UTC +11:00 Bougainville, Efate, Guadalcanal, Kosrae, Norfolk, Noumea, Pohnpei, and Ponape Time",
  offset: -660
}, {
  name: "UTC +11:00 LHI and Lord Howe Time",
  offset: -660
}, {
  name: "UTC +11:00 Macquarie Time",
  offset: -660
}, {
  name: "UTC +11:00 Magadan, Sakhalin, and Srednekolymsk Time",
  offset: -660
}, {
  name: "UTC +12:00 Anadyr and Kamchatka Time",
  offset: -720
}, {
  name: "UTC +12:00 Funafuti, Kwajalein, Majuro, Nauru, Tarawa, Wake, and Wallis Time",
  offset: -720
}, {
  name: "UTC +13:00 Auckland Time",
  offset: -780
}, {
  name: "UTC +13:00 Enderbury, Fakaofo, and Tongatapu Time",
  offset: -780
}, {
  name: "UTC +13:00 Fiji Time",
  offset: -780
}, {
  name: "UTC +13:00 McMurdo and South Pole Time",
  offset: -780
}, {
  name: "UTC +13:45 Chatham Time",
  offset: -825
}, {
  name: "UTC +14:00 Apia Time",
  offset: -840
}, {
  name: "UTC +14:00 Kiritimati Time",
  offset: -840
}, {
  name: "UTC -01:00 Azores Time",
  offset: 60
}, {
  name: "UTC -01:00 Cape Verde Time",
  offset: 60
}, {
  name: "UTC -01:00 Scoresbysund Time",
  offset: 60
}, {
  name: "UTC -02:00 DeNoronha Time",
  offset: 120
}, {
  name: "UTC -02:00 Noronha Time",
  offset: 120
}, {
  name: "UTC -02:00 South Georgia Time",
  offset: 120
}, {
  name: "UTC -03:00 Argentinian Time",
  offset: 180
}, {
  name: "UTC -03:00 Asuncion Time",
  offset: 180
}, {
  name: "UTC -03:00 Continental Time",
  offset: 180
}, {
  name: "UTC -03:00 East Time",
  offset: 180
}, {
  name: "UTC -03:00 Godthab Time",
  offset: 180
}, {
  name: "UTC -03:00 Miquelon Time",
  offset: 180
}, {
  name: "UTC -03:00 Palmer and Rothera Time",
  offset: 180
}, {
  name: "UTC -03:00 Santiago Time",
  offset: 180
}, {
  name: "UTC -03:30 Newfoundland Time",
  offset: 150
}, {
  name: "UTC -03:30 St Johns Time",
  offset: 150
}, {
  name: "UTC -04:00 Atlantic Time",
  offset: 240
}, {
  name: "UTC -04:00 Bermuda Time",
  offset: 240
}, {
  name: "UTC -04:00 Eastern Carribean Time",
  offset: 240
}, {
  name: "UTC -04:00 Glace Bay, Goose Bay, Halifax, Moncton, and Thule Time",
  offset: 240
}, {
  name: "UTC -04:00 West Time",
  offset: 240
}, {
  name: "UTC -05:00 Acre Time",
  offset: 300
}, {
  name: "UTC -05:00 Easter Time",
  offset: 300
}, {
  name: "UTC -05:00 EasterIsland Time",
  offset: 300
}, {
  name: "UTC -05:00 Eastern Time",
  offset: 300
}, {
  name: "UTC -05:00 Havana Time",
  offset: 300
}, {
  name: "UTC -05:00 Jamaica and Panama Time",
  offset: 300
}, {
  name: "UTC -06:00 Bahia Banderas, Merida, Mexico City, and Monterrey Time",
  offset: 360
}, {
  name: "UTC -06:00 Belize, Costa Rica, El Salvador, Guatemala, Managua, Regina, Swift Current, and Tegucigalpa Time",
  offset: 360
}, {
  name: "UTC -06:00 Central Time",
  offset: 360
}, {
  name: "UTC -06:00 Galapagos Time",
  offset: 360
}, {
  name: "UTC -06:00 General Time",
  offset: 360
}, {
  name: "UTC -06:00 Saskatchewan Time",
  offset: 360
}, {
  name: "UTC -07:00 Arizona Time",
  offset: 420
}, {
  name: "UTC -07:00 BajaSur Time",
  offset: 420
}, {
  name: "UTC -07:00 Chihuahua and Mazatlan Time",
  offset: 420
}, {
  name: "UTC -07:00 Mountain Time",
  offset: 420
}, {
  name: "UTC -08:00 BajaNorte Time",
  offset: 480
}, {
  name: "UTC -08:00 Pacific Time",
  offset: 480
}, {
  name: "UTC -08:00 Pacific and Yukon Time",
  offset: 480
}, {
  name: "UTC -08:00 Pitcairn Time",
  offset: 480
}, {
  name: "UTC -09:00 Anchorage, Juneau, Metlakatla, Nome, Sitka, and Yakutat Time",
  offset: 540
}, {
  name: "UTC -09:00 Gambier Time",
  offset: 540
}, {
  name: "UTC -09:30 Marquesas Time",
  offset: 510
}, {
  name: "UTC -10:00 Adak and Atka Time",
  offset: 600
}, {
  name: "UTC -10:00 Honolulu, Johnston, Rarotonga, and Tahiti Time",
  offset: 600
}, {
  name: "UTC -11:00 Midway, Niue, Pago Pago, and Samoa Time",
  offset: 660
}];
exports.AutotaskFilterDataType = void 0;
(function (AutotaskFilterDataType) {
  AutotaskFilterDataType["Checkbox"] = "checkbox";
  AutotaskFilterDataType["Dropdown"] = "dropdown";
  AutotaskFilterDataType["Datepicker"] = "date";
  AutotaskFilterDataType["InputText"] = "text";
  AutotaskFilterDataType["InputNumber"] = "number";
})(exports.AutotaskFilterDataType || (exports.AutotaskFilterDataType = {}));
exports.AutotaskSyncProperty = void 0;
(function (AutotaskSyncProperty) {
  AutotaskSyncProperty["Push"] = "push";
  AutotaskSyncProperty["Pull"] = "pull";
  AutotaskSyncProperty["Pull_Always_Push_On_Won"] = "push_n_pull";
  AutotaskSyncProperty["PushPullAlways"] = "push_n_pull_realtime";
})(exports.AutotaskSyncProperty || (exports.AutotaskSyncProperty = {}));
exports.IntegrationMode = void 0;
(function (IntegrationMode) {
  IntegrationMode["CPQ"] = "cpq";
  IntegrationMode["CRM"] = "crm";
})(exports.IntegrationMode || (exports.IntegrationMode = {}));
var generateCustomFieldMappingRequest = function generateCustomFieldMappingRequest(mapping) {
  var customFieldMapping = [];
  Object.keys(mapping).forEach(function (key) {
    var _a, _b;
    var autotaskCustomField = (_b = (_a = mapping[key]) === null || _a === void 0 ? void 0 : _a.split(".")) === null || _b === void 0 ? void 0 : _b[1];
    if (!autotaskCustomField) return;
    customFieldMapping.push({
      zomentum_custom_field: key,
      autotask_custom_field: autotaskCustomField
    });
  });
  return customFieldMapping;
};
exports.ConnectwiseCustomFieldType = void 0;
(function (ConnectwiseCustomFieldType) {
  ConnectwiseCustomFieldType["Button"] = "Button";
  ConnectwiseCustomFieldType["Checkbox"] = "Checkbox";
  ConnectwiseCustomFieldType["Date"] = "Date";
  ConnectwiseCustomFieldType["Hyperlink"] = "Hyperlink";
  ConnectwiseCustomFieldType["Number"] = "Number";
  ConnectwiseCustomFieldType["Percent"] = "Percent";
  ConnectwiseCustomFieldType["Text"] = "Text";
  ConnectwiseCustomFieldType["TextArea"] = "TextArea";
})(exports.ConnectwiseCustomFieldType || (exports.ConnectwiseCustomFieldType = {}));
exports.AutotaskCustomFieldType = void 0;
(function (AutotaskCustomFieldType) {
  AutotaskCustomFieldType["Decimal"] = "decimal";
  AutotaskCustomFieldType["Double"] = "double";
  AutotaskCustomFieldType["Long"] = "long";
  AutotaskCustomFieldType["Integer"] = "integer";
  AutotaskCustomFieldType["Short"] = "short";
  AutotaskCustomFieldType["String"] = "string";
  AutotaskCustomFieldType["Date"] = "datetime";
})(exports.AutotaskCustomFieldType || (exports.AutotaskCustomFieldType = {}));
exports.ImportTaxOption = void 0;
(function (ImportTaxOption) {
  ImportTaxOption["Import"] = "import";
  ImportTaxOption["DeactivateCurrentAndImport"] = "deactivate_current_and_import";
})(exports.ImportTaxOption || (exports.ImportTaxOption = {}));
exports.TaxRegionTaxable = void 0;
(function (TaxRegionTaxable) {
  TaxRegionTaxable["Taxable"] = "Taxable";
  TaxRegionTaxable["NonTaxable"] = "Non taxable";
})(exports.TaxRegionTaxable || (exports.TaxRegionTaxable = {}));
exports.ClientCompaniesToImport = void 0;
(function (ClientCompaniesToImport) {
  ClientCompaniesToImport["AllClientCompanyHierarchy"] = "AllClientCompanyHierarchy";
  ClientCompaniesToImport["ClientCompanyHierarchyWithAtLeastOneOpportunity"] = "ClientCompanyHierarchyWithAtLeastOneOpportunity";
  ClientCompaniesToImport["NoClientCompanies"] = "NoClientCompanies";
})(exports.ClientCompaniesToImport || (exports.ClientCompaniesToImport = {}));
exports.SyncOption = void 0;
(function (SyncOption) {
  SyncOption["ImportOnly"] = "ImportOnly";
  SyncOption["ExportOnly"] = "ExportOnly";
  SyncOption["TwoWaySync"] = "TwoWaySync";
  SyncOption["RealTimeSync"] = "RealTimeSync";
})(exports.SyncOption || (exports.SyncOption = {}));
exports.EstimateOption = void 0;
(function (EstimateOption) {
  EstimateOption["SINGLE_ESTIMATE"] = "Single Estimate";
  EstimateOption["MULTIPLE_ESTIMATE"] = "Multiple Estimate";
})(exports.EstimateOption || (exports.EstimateOption = {}));
exports.EstimateOptionsValues = void 0;
(function (EstimateOptionsValues) {
  EstimateOptionsValues["SINGLE_ESTIMATE"] = "single_estimate";
  EstimateOptionsValues["MULTIPLE_ESTIMATE"] = "multiple_estimate";
})(exports.EstimateOptionsValues || (exports.EstimateOptionsValues = {}));
exports.ProductExportMode = void 0;
(function (ProductExportMode) {
  ProductExportMode["Individual"] = "individual";
  ProductExportMode["Bundle"] = "bundle";
  ProductExportMode["ConnectwiseBundle"] = "connectwiseBundle";
})(exports.ProductExportMode || (exports.ProductExportMode = {}));
exports.CWProductFieldMappingName = void 0;
(function (CWProductFieldMappingName) {
  CWProductFieldMappingName["Identifier"] = "identifier";
  CWProductFieldMappingName["Description"] = "description";
})(exports.CWProductFieldMappingName || (exports.CWProductFieldMappingName = {}));
var CWProductFieldMapping = /** @class */function () {
  function CWProductFieldMapping() {
    this.name = exports.CWProductFieldMappingName.Identifier;
    this.connectwise_product_id_mapping = "";
  }
  return CWProductFieldMapping;
}();
var ConnectWiseIntegration = /** @class */function () {
  function ConnectWiseIntegration() {
    this.connected_user = "";
    this.credentials_created = "";
    this.credentials_updated = "";
    this.host = "";
    this.connectwise_company_id = "";
    this.public_api_key = "";
    this.private_api_key = "";
    this.sync_option = exports.SyncOption.ExportOnly;
    this.mode = exports.IntegrationMode.CRM;
    this.client_companies_to_import = exports.ClientCompaniesToImport.NoClientCompanies;
    this.company_types_to_import = new Array();
    this.contact_types_to_import = new Array();
    this.company_markets_to_import = new Array();
    this.should_import_product = false;
    this.document_push_filter = false;
    this.default_values = new ConnectwiseDefaultValues();
    this.stage_mapping = new Array();
    this.product_mapping = new Array();
    this.product_field_mapping = new CWProductFieldMapping();
    this.opportunity_owners_to_import = new Array();
    this.opportunity_stages_to_import = new Array();
    this.opportunity_status_to_import = new Array();
    this.opportunity_updated_at_filter = new Date();
    this.product_sync_details = null;
    this.product_export_mode = null;
    this.tax_mapping = new Array();
    this.unit_of_measure_mapping = new Array();
    this.should_push_closed_opportunity = false;
    this.is_initial_sync_pending = false;
    this.item_custom_field_mapping = [];
    this.client_user_custom_field_mapping = [];
    this.client_company_custom_field_mapping = [];
    this.opportunity_custom_field_mapping = [];
  }
  return ConnectWiseIntegration;
}();
var SyncroSettingsRequest = /** @class */function () {
  function SyncroSettingsRequest() {
    this.sync_option = exports.SyncOption.ExportOnly;
    this.should_import_client_hierarchy = false;
    this.should_split_line_items = false;
    this.push_tax_inclusive_amount_in_quotes = true;
  }
  return SyncroSettingsRequest;
}();
var SyncroFieldMappingRequest = /** @class */function () {
  function SyncroFieldMappingRequest() {
    this.product_mapping = new Array();
    this.generate_fields = false;
  }
  return SyncroFieldMappingRequest;
}();
var StringifySyncroProduct = /** @class */function () {
  function StringifySyncroProduct(args) {
    this.id = args.id;
    this.account_id = args.account_id;
    this.ancestry = args.ancestry;
    this.name = args.name;
    this.description = args.description;
    this.device_product_id = args.device_product_id;
    this.names_depth_cache = args.names_depth_cache;
  }
  return StringifySyncroProduct;
}();
var AddSyncroIntegrationRequest = /** @class */function () {
  function AddSyncroIntegrationRequest() {
    this.product_category_to_import = null;
    this.product_mapping = new Array();
    this.generate_fields = false;
    this.push_tax_inclusive_amount_in_quotes = true;
  }
  return AddSyncroIntegrationRequest;
}();
var SyncroCredentialsTestRequest = /** @class */function () {
  function SyncroCredentialsTestRequest(args) {
    this.api_key = "";
    this.sub_domain = "";
    this.api_key = args.api_key;
    this.sub_domain = args.sub_domain;
  }
  return SyncroCredentialsTestRequest;
}();
var SyncroIntegration = /** @class */function () {
  function SyncroIntegration() {
    this.connected_user = "";
    this.credentials_created = new Date();
    this.credentials_updated = new Date();
    this.should_import_client_hierarchy = false;
    this.should_import_product = false;
    this.client_created_at_filter = new Date();
    this.product_category_to_import = new Array();
    this.sync_option = exports.SyncOption.ExportOnly;
    this.product_mapping = new Array();
    this.should_split_line_items = false;
    this.generate_fields = false;
    this.is_initial_sync_pending = false;
    this.push_tax_inclusive_amount_in_quotes = true;
  }
  return SyncroIntegration;
}();
var RepairShoprIntegration = /** @class */function () {
  function RepairShoprIntegration() {
    this.connected_user = "";
    this.credentials_created = new Date();
    this.credentials_updated = new Date();
    this.should_import_client_hierarchy = false;
    this.should_import_product = false;
    this.client_created_at_filter = new Date();
    this.product_category_to_import = new Array();
    this.sync_option = exports.SyncOption.ExportOnly;
    this.product_mapping = new Array();
    this.should_split_line_items = false;
    this.generate_fields = false;
    this.is_initial_sync_pending = false;
  }
  return RepairShoprIntegration;
}();
exports.EmailIntegrationProvider = void 0;
(function (EmailIntegrationProvider) {
  EmailIntegrationProvider["Gmail"] = "gmail";
  EmailIntegrationProvider["EAS"] = "eas";
  EmailIntegrationProvider["EWS"] = "ews";
  EmailIntegrationProvider["Microsoft"] = "microsoft";
})(exports.EmailIntegrationProvider || (exports.EmailIntegrationProvider = {}));
exports.AccountVisibilityStatus = void 0;
(function (AccountVisibilityStatus) {
  AccountVisibilityStatus["Public"] = "public";
  AccountVisibilityStatus["Private"] = "private";
})(exports.AccountVisibilityStatus || (exports.AccountVisibilityStatus = {}));
var EmailIntegration = /** @class */function () {
  function EmailIntegration() {
    this.account_id = "";
    this.email = "";
    this.provider = exports.EmailIntegrationProvider.Gmail;
    this.user_id = "";
    this.email_aliases = new Array();
    this.calendar_connected_settings = null;
    this.connected_modules = new Array();
    this.account_visibility_state = exports.AccountVisibilityStatus.Public;
  }
  return EmailIntegration;
}();
exports.EmailDigestType = void 0;
(function (EmailDigestType) {
  EmailDigestType["DAILY"] = "Daily";
  EmailDigestType["WEEKLY"] = "Weekly";
})(exports.EmailDigestType || (exports.EmailDigestType = {}));
var ConnectCalendarPostRequest = /** @class */function () {
  function ConnectCalendarPostRequest() {
    this.calendar_id = "";
    this.two_way_sync = false;
    this.one_way_sync = false;
    this.should_sync_tasks_from_zomentum_to_calendar = false;
    this.should_create_contacts_from_attendees = false;
    this.ignored_domains = new Array();
  }
  return ConnectCalendarPostRequest;
}();
var EndUserLicenseAgreement = /** @class */function () {
  function EndUserLicenseAgreement() {
    this.acceptance_time = 0;
    this.accepted = false;
    this.accepted_by_user = "";
    this.accepted_version = "";
  }
  return EndUserLicenseAgreement;
}();
var UserCredentials = /** @class */function () {
  function UserCredentials() {
    this.password = "";
    this.userName = "";
  }
  return UserCredentials;
}();
var KaseyaIntegration = /** @class */function () {
  function KaseyaIntegration() {
    this.connected_user = "";
    this.last_synced_at = "";
    this.two_way_sync = true;
    this.product_sync_detail = null;
    this.pull_updates = false;
    this.is_initial_sync_pending = false;
  }
  return KaseyaIntegration;
}();
var AutotaskIntegration = /** @class */function () {
  function AutotaskIntegration() {
    this.connected_user = "";
    this.last_synced_at = 0;
    this.two_way_sync = false;
    this.is_initial_sync_pending = false;
  }
  return AutotaskIntegration;
}();
var ZapierIntegration = /** @class */function () {
  function ZapierIntegration() {
    this.connected_users = new Array();
    this.last_synced_at = 0;
  }
  return ZapierIntegration;
}();
exports.IngramCredentialType = void 0;
(function (IngramCredentialType) {
  IngramCredentialType["XML"] = "xml";
  IngramCredentialType["API"] = "api";
})(exports.IngramCredentialType || (exports.IngramCredentialType = {}));
var IngramIntegration = /** @class */function () {
  function IngramIntegration() {
    this.connected_user = "";
    this.cred_type = exports.IngramCredentialType.XML;
  }
  return IngramIntegration;
}();
var TechDataIntegration = /** @class */function () {
  function TechDataIntegration() {
    this.connected_user = "";
  }
  return TechDataIntegration;
}();
var DAndHIntegration = /** @class */function () {
  function DAndHIntegration() {
    this.connected_user = "";
  }
  return DAndHIntegration;
}();
var SynnexIntegration = /** @class */function () {
  function SynnexIntegration() {
    this.connected_user = "";
  }
  return SynnexIntegration;
}();
var ConnectBoosterIntegration = /** @class */function () {
  function ConnectBoosterIntegration() {}
  return ConnectBoosterIntegration;
}();
var StripeIntegration = /** @class */function () {
  function StripeIntegration() {
    this.account_id = "";
  }
  return StripeIntegration;
}();
var GoogleDriveIntegration = /** @class */function () {
  function GoogleDriveIntegration() {
    this.connected_user = "";
  }
  return GoogleDriveIntegration;
}();
exports.PaymentsState = void 0;
(function (PaymentsState) {
  PaymentsState["Pending"] = "pending";
  PaymentsState["Valid"] = "valid";
  PaymentsState["Rejected"] = "rejected";
  PaymentsState["Invalid"] = "invalid";
})(exports.PaymentsState || (exports.PaymentsState = {}));
var ZMPaymentsIntegraton = /** @class */function () {
  function ZMPaymentsIntegraton() {
    this.receive_payments = exports.PaymentsState.Invalid;
    this.payouts_type = exports.PaymentsState.Invalid;
    this.card_status = false;
    this.direct_debit_status = false;
  }
  return ZMPaymentsIntegraton;
}();
exports.DistributorRegions = void 0;
(function (DistributorRegions) {
  DistributorRegions["USA"] = "us";
  DistributorRegions["Canada"] = "ca";
  DistributorRegions["UK"] = "uk";
  DistributorRegions["Australia"] = "au";
  DistributorRegions["NZ"] = "nz";
  DistributorRegions["BE"] = "be";
  DistributorRegions["AT"] = "at";
  DistributorRegions["CH"] = "ch";
  DistributorRegions["DE"] = "de";
  DistributorRegions["SE"] = "se";
  DistributorRegions["ES"] = "es";
  DistributorRegions["FR"] = "fr";
  DistributorRegions["IT"] = "it";
  DistributorRegions["PT"] = "pt";
  DistributorRegions["HU"] = "hu";
  DistributorRegions["Netherlands"] = "nl";
})(exports.DistributorRegions || (exports.DistributorRegions = {}));
exports.IngramRegions = void 0;
(function (IngramRegions) {
  IngramRegions["us"] = "USA";
  IngramRegions["ca"] = "Canada";
  IngramRegions["uk"] = "UK";
  IngramRegions["au"] = "Australia";
  IngramRegions["nz"] = "New zealand";
  IngramRegions["at"] = "Austria";
  IngramRegions["ch"] = "Switzerland";
  IngramRegions["de"] = "Germany";
  IngramRegions["se"] = "Sweden";
  IngramRegions["es"] = "Spain";
  IngramRegions["fr"] = "France";
  IngramRegions["it"] = "Italy";
  IngramRegions["pt"] = "Portugal";
  IngramRegions["hu"] = "Hungary";
  IngramRegions["nl"] = "Netherlands";
})(exports.IngramRegions || (exports.IngramRegions = {}));
var IngramApiRegions = [exports.DistributorRegions.USA, exports.DistributorRegions.Canada, exports.DistributorRegions.UK, exports.DistributorRegions.AT, exports.DistributorRegions.CH, exports.DistributorRegions.DE, exports.DistributorRegions.SE, exports.DistributorRegions.ES, exports.DistributorRegions.FR, exports.DistributorRegions.IT, exports.DistributorRegions.PT, exports.DistributorRegions.HU, exports.DistributorRegions.BE, exports.DistributorRegions.Australia, exports.DistributorRegions.NZ, exports.DistributorRegions.Netherlands];
var IngramXmlRegions = [exports.DistributorRegions.USA, exports.DistributorRegions.Canada, exports.DistributorRegions.UK, exports.DistributorRegions.NZ, exports.DistributorRegions.Australia, exports.DistributorRegions.Netherlands];
var TechDataCustomerNoRegions = [exports.DistributorRegions.UK, exports.DistributorRegions.BE];
var prefillContent = "****************************";
var InfusionSoftIntegration = /** @class */function () {
  function InfusionSoftIntegration() {
    this.connected_user = "";
    this.two_way_sync = false;
    this.is_initial_sync_pending = false;
  }
  return InfusionSoftIntegration;
}();
var InfusionSoftSettingsRequest = /** @class */function () {
  function InfusionSoftSettingsRequest() {
    this.sync_option = exports.SyncOption.ExportOnly;
    this.should_sync_opp_related_data_only = false;
  }
  return InfusionSoftSettingsRequest;
}();
exports.InfusionSoftStageMappingField = void 0;
(function (InfusionSoftStageMappingField) {
  InfusionSoftStageMappingField["opportunity_stage"] = "opportunity_stage";
})(exports.InfusionSoftStageMappingField || (exports.InfusionSoftStageMappingField = {}));
exports.CustomDistributorConnectionMethods = void 0;
(function (CustomDistributorConnectionMethods) {
  CustomDistributorConnectionMethods["FTP"] = "ftp";
  CustomDistributorConnectionMethods["SFTP"] = "sftp";
  CustomDistributorConnectionMethods["MANUAL"] = "manual";
  CustomDistributorConnectionMethods["API"] = "api";
  CustomDistributorConnectionMethods["SECURE_API"] = "via_secure_url";
})(exports.CustomDistributorConnectionMethods || (exports.CustomDistributorConnectionMethods = {}));
exports.CustomDistributorConnectionType = void 0;
(function (CustomDistributorConnectionType) {
  CustomDistributorConnectionType["PUSH"] = "push";
  CustomDistributorConnectionType["PULL"] = "pull";
})(exports.CustomDistributorConnectionType || (exports.CustomDistributorConnectionType = {}));
exports.ConnectionTypeServerKey = void 0;
(function (ConnectionTypeServerKey) {
  ConnectionTypeServerKey["VIA_URL"] = "via_url";
  ConnectionTypeServerKey["VIA_FTP"] = "via_ftp_pull";
  ConnectionTypeServerKey["VIA_SFTP"] = "via_sftp_pull";
  ConnectionTypeServerKey["VIA_FTP_PUSH"] = "via_ftp_push";
  ConnectionTypeServerKey["VIA_API_PUSH"] = "via_api_push";
  ConnectionTypeServerKey["VIA_MANUAL_PUSH"] = "via_manual_push";
  ConnectionTypeServerKey["VIA_SECURE_URL"] = "via_secure_url";
})(exports.ConnectionTypeServerKey || (exports.ConnectionTypeServerKey = {}));
var getConnectionTypeServerKey = function getConnectionTypeServerKey(connectionType, connectionMethod) {
  if (connectionType === exports.CustomDistributorConnectionType.PULL) {
    switch (connectionMethod) {
      case exports.CustomDistributorConnectionMethods.API:
        return exports.ConnectionTypeServerKey.VIA_URL;
      case exports.CustomDistributorConnectionMethods.FTP:
        return exports.ConnectionTypeServerKey.VIA_FTP;
      case exports.CustomDistributorConnectionMethods.SFTP:
        return exports.ConnectionTypeServerKey.VIA_SFTP;
      case exports.CustomDistributorConnectionMethods.SECURE_API:
        return exports.ConnectionTypeServerKey.VIA_SECURE_URL;
      default:
        return exports.ConnectionTypeServerKey.VIA_URL;
    }
  } else {
    switch (connectionMethod) {
      case exports.CustomDistributorConnectionMethods.API:
        return exports.ConnectionTypeServerKey.VIA_API_PUSH;
      case exports.CustomDistributorConnectionMethods.FTP:
      case exports.CustomDistributorConnectionMethods.SFTP:
        return exports.ConnectionTypeServerKey.VIA_FTP_PUSH;
      case exports.CustomDistributorConnectionMethods.MANUAL:
        return exports.ConnectionTypeServerKey.VIA_MANUAL_PUSH;
      default:
        return exports.ConnectionTypeServerKey.VIA_API_PUSH;
    }
  }
};
var CustomDistributorFTPConnectionRequest = /** @class */function () {
  function CustomDistributorFTPConnectionRequest() {
    this.user_name = "";
    this.password = "";
    this.server = "";
  }
  return CustomDistributorFTPConnectionRequest;
}();
var MetaDataRequest = /** @class */function () {
  function MetaDataRequest() {
    this.connection_type = exports.ConnectionTypeServerKey.VIA_URL;
    this.id = "";
  }
  return MetaDataRequest;
}();
var CustomDistributorSecureApiConnectionRequest = /** @class */function () {
  function CustomDistributorSecureApiConnectionRequest() {
    this.user_name = "";
    this.password = "";
  }
  return CustomDistributorSecureApiConnectionRequest;
}();
var PricingField = /** @class */function () {
  function PricingField() {
    this.field = "";
  }
  return PricingField;
}();
var HeadersField = /** @class */function () {
  function HeadersField() {
    this.field = "";
    this.display_name = "";
    this.data_type = "";
  }
  return HeadersField;
}();
var FieldMappingRequest = /** @class */function () {
  function FieldMappingRequest() {
    this.distributor_field = "";
    this.zomentum_field = "";
  }
  return FieldMappingRequest;
}();
var FieldMappingResponse = /** @class */function () {
  function FieldMappingResponse() {
    this.distributor_field = null;
    this.zomentum_field = new PricingField();
  }
  return FieldMappingResponse;
}();
var CustomDistributorUpdateRequest = /** @class */function () {
  function CustomDistributorUpdateRequest() {
    this.id = "";
    this.mappings = [];
    this.integration_name = "";
    this.vendor = "";
    this.connection_details = {
      connection_type: exports.ConnectionTypeServerKey.VIA_URL,
      data: {}
    };
  }
  return CustomDistributorUpdateRequest;
}();
var ConnectionDetailsResponse = /** @class */function () {
  function ConnectionDetailsResponse() {
    this.connection_type = exports.ConnectionTypeServerKey.VIA_URL;
  }
  return ConnectionDetailsResponse;
}();
var CustomDistributorIntegration = /** @class */function () {
  function CustomDistributorIntegration() {
    this.id = "";
    this.user_id = "";
    this.integration_name = "";
    this.vendor = "";
    this.connection_details = new ConnectionDetailsResponse();
    this.mappings = [];
    this.file_name = "";
  }
  return CustomDistributorIntegration;
}();
exports.CustomDistributorPageType = void 0;
(function (CustomDistributorPageType) {
  CustomDistributorPageType["EDIT"] = "EDIT";
  CustomDistributorPageType["ADD"] = "ADD";
})(exports.CustomDistributorPageType || (exports.CustomDistributorPageType = {}));
var Integrations = /** @class */function () {
  function Integrations() {
    this.connectwise = new ConnectWiseIntegration();
    this.email = new Array();
    this.autotask = new AutotaskIntegration();
    this.kaseya = new KaseyaIntegration();
    this.zapier = new ZapierIntegration();
    this.ingram = new IngramIntegration();
    this.tech_data = new TechDataIntegration();
    this.d_and_h = new DAndHIntegration();
    this.synnex = new SynnexIntegration();
    this.syncro = new SyncroIntegration();
    this.repair_shopr = new RepairShoprIntegration();
    this.connect_booster = new ConnectBoosterIntegration();
    this.infusion_soft = new InfusionSoftIntegration();
    this.hubspot = new HubspotIntegration();
    this.westcoast = null;
    this.stripe = null;
    this.quickbooks = null;
    this.halo = null;
    this.custom_distributor = null;
    this.payments = null;
    this.integrations_page_details = new IntegrationPageDetails();
    this.amazon_business = null;
    this.google_cloud = null;
  }
  return Integrations;
}();
var IntegrationPageDetails = /** @class */function () {
  function IntegrationPageDetails() {
    this.favourite_integrations = [];
    this.integrations_page_visited = false;
  }
  return IntegrationPageDetails;
}();
// Every new integration (Distributors) goes below too for etilize to map
exports.Distributors = void 0;
(function (Distributors) {
  Distributors["Ingram"] = "ingram";
  Distributors["TechData"] = "tech_data";
  Distributors["DAndH"] = "d_and_h";
  Distributors["Synnex"] = "synnex";
  Distributors["DickerData"] = "dicker_data";
  Distributors["Westcoast"] = "westcoast";
  Distributors["CustomDistributor"] = "custom_distributor";
})(exports.Distributors || (exports.Distributors = {}));
exports.Locale = void 0;
(function (Locale) {
  Locale["US"] = "en-US";
  Locale["UK"] = "en-UK";
  Locale["AU"] = "en-AU";
})(exports.Locale || (exports.Locale = {}));
exports.EtilizeLocale = void 0;
(function (EtilizeLocale) {
  EtilizeLocale["US"] = "en_us";
  EtilizeLocale["UK"] = "en_uk";
  EtilizeLocale["AU"] = "en_au";
  EtilizeLocale["NL"] = "en_nl";
  EtilizeLocale["BE"] = "en_be";
  EtilizeLocale["DE"] = "en_de";
  EtilizeLocale["FR"] = "en_fr";
})(exports.EtilizeLocale || (exports.EtilizeLocale = {}));
exports.SimilarEtilzieRegions = void 0;
(function (SimilarEtilzieRegions) {
  SimilarEtilzieRegions["ca"] = "us";
  SimilarEtilzieRegions["nz"] = "au";
})(exports.SimilarEtilzieRegions || (exports.SimilarEtilzieRegions = {}));
var etilizeProductContentSources = [{
  name: "North America (USA and Canada)",
  value: exports.EtilizeLocale.US
}, {
  name: "United Kingdom (UK)",
  value: exports.EtilizeLocale.UK
}, {
  name: "Australia (AU)",
  value: exports.EtilizeLocale.AU
}, {
  name: "Netherlands (NL)",
  value: exports.EtilizeLocale.NL
}, {
  name: "Belgium (BE)",
  value: exports.EtilizeLocale.BE
}, {
  name: "Germany (DE)",
  value: exports.EtilizeLocale.DE
}];
var icecatLocaleArray = [{
  name: "North America (USA and Canada)",
  value: exports.EtilizeLocale.US
}, {
  name: "United Kingdom of Great Britain and Northern Ireland (UK)",
  value: exports.EtilizeLocale.UK
}, {
  name: "Australia (AU & NZ)",
  value: exports.EtilizeLocale.AU
}, {
  name: "Netherlands (NL)",
  value: exports.EtilizeLocale.NL
}, {
  name: "Belgium (BE)",
  value: exports.EtilizeLocale.BE
}, {
  name: "Germany (DE)",
  value: exports.EtilizeLocale.DE
}, {
  name: "France (FR)",
  value: exports.EtilizeLocale.FR
}];
var IntegrationsResponse = /** @class */function () {
  function IntegrationsResponse() {
    this.base = "";
    this.integrations = new Integrations();
  }
  return IntegrationsResponse;
}();
var AddEmailIntegrationRequest = /** @class */function () {
  function AddEmailIntegrationRequest() {
    this.state = "";
    this.code = "";
  }
  return AddEmailIntegrationRequest;
}();
var DeleteConnectWiseIntegrationRequest = /** @class */function () {
  function DeleteConnectWiseIntegrationRequest() {
    this.connectwise = new ConnectWiseIntegration();
  }
  return DeleteConnectWiseIntegrationRequest;
}();
exports.IntgerationPullSelectOption = void 0;
(function (IntgerationPullSelectOption) {
  IntgerationPullSelectOption["PullAll"] = "PullAll";
  IntgerationPullSelectOption["PullSelected"] = "PullSelected";
})(exports.IntgerationPullSelectOption || (exports.IntgerationPullSelectOption = {}));
var ConnectwiseDefaultValues = /** @class */function () {
  function ConnectwiseDefaultValues() {}
  return ConnectwiseDefaultValues;
}();
var ConnectwiseCustomFieldMapping = /** @class */function () {
  function ConnectwiseCustomFieldMapping(zmId, cwId) {
    this.cw_custom_field = "";
    this.zm_custom_field = "";
    this.cw_custom_field = cwId;
    this.zm_custom_field = zmId;
  }
  return ConnectwiseCustomFieldMapping;
}();
var ConnectwiseSettingsRequest = /** @class */function () {
  function ConnectwiseSettingsRequest() {
    this.client_companies_to_import = exports.ClientCompaniesToImport.NoClientCompanies;
    this.company_types_to_import = new Array();
    this.contact_types_to_import = new Array();
    this.company_markets_to_import = new Array();
    this.should_import_product = false;
    this.document_push_filter = false;
    this.mode = exports.IntegrationMode.CPQ;
    this.opportunity_stages_to_import = new Array();
    this.opportunity_status_to_import = new Array();
    this.opportunity_updated_at_filter = null;
    this.product_sync_details = null;
    this.product_export_mode = null;
    this.should_push_closed_opportunity = false;
    this.opportunity_owners_to_import = [];
    this.create_client_on_opportunity_push = false;
    this.item_custom_field_mapping = [];
    this.client_user_custom_field_mapping = [];
    this.client_company_custom_field_mapping = [];
    this.opportunity_custom_field_mapping = [];
  }
  return ConnectwiseSettingsRequest;
}();
var OpportunitySyncInfo = /** @class */function () {
  function OpportunitySyncInfo() {
    this.pull_as_won = false;
    this.pull_as_lost = false;
  }
  return OpportunitySyncInfo;
}();
var ConnectwiseFieldMappingRequest = /** @class */function () {
  function ConnectwiseFieldMappingRequest() {
    this.default_values = null;
    this.stage_mapping = new Array();
    this.product_mapping = new Array();
    this.tax_mapping = new Array();
    this.generate_fields = false;
    this.is_item_custom_fields_non_editable = false;
    this.product_field_mapping = new CWProductFieldMapping();
    this.integrated_pipeline_id = "";
    this.unit_of_measure_mapping = new Array();
    this.use_stage_mapping_only = false;
    this.opportunity_sync_info = new OpportunitySyncInfo();
    this.should_push_closed_opportunity = false;
    this.item_custom_field_mapping = [];
    this.client_user_custom_field_mapping = [];
    this.client_company_custom_field_mapping = [];
    this.opportunity_custom_field_mapping = [];
  }
  return ConnectwiseFieldMappingRequest;
}();
var StringifiedKeyValuePair = /** @class */function () {
  function StringifiedKeyValuePair(id, name) {
    this.id = 0;
    this.name = "";
    this.id = id;
    this.name = name;
  }
  return StringifiedKeyValuePair;
}();
exports.ConnectwiseRegions = void 0;
(function (ConnectwiseRegions) {
  ConnectwiseRegions["NorthAmerica"] = "north_america";
  ConnectwiseRegions["Europe"] = "europe";
  ConnectwiseRegions["Australia"] = "australia";
  ConnectwiseRegions["SelfHosted"] = "self_hosted";
})(exports.ConnectwiseRegions || (exports.ConnectwiseRegions = {}));
var ConnectwiseHost = (_a = {}, _a[exports.ConnectwiseRegions.NorthAmerica] = "https://api-na.myconnectwise.net/v2020_2/apis/3.0/", _a[exports.ConnectwiseRegions.Europe] = "https://api-eu.myconnectwise.net/v2020_2/apis/3.0/", _a[exports.ConnectwiseRegions.Australia] = "https://api-au.myconnectwise.net/v2020_2/apis/3.0/", _a);
var ConnectwiseCredentialsTestRequest = /** @class */function () {
  function ConnectwiseCredentialsTestRequest(args) {
    this.host = "";
    this.connectwise_company_id = "";
    this.public_api_key = "";
    this.private_api_key = "";
    this.host = args.host;
    this.connectwise_company_id = args.connectwise_company_id;
    this.public_api_key = args.public_api_key;
    this.private_api_key = args.private_api_key;
  }
  return ConnectwiseCredentialsTestRequest;
}();
var ImportTaxRequest = /** @class */function () {
  function ImportTaxRequest() {
    this.deactivate_existing = false;
  }
  return ImportTaxRequest;
}();
exports.ConnectwiseEntities = void 0;
(function (ConnectwiseEntities) {
  ConnectwiseEntities["OPPORTUNITY"] = "opportunity";
  ConnectwiseEntities["COMPANY"] = "company";
})(exports.ConnectwiseEntities || (exports.ConnectwiseEntities = {}));
var AddConnectWiseIntegrationRequest = /** @class */function () {
  function AddConnectWiseIntegrationRequest() {
    this.host = "";
    this.connectwise_company_id = "";
    this.public_api_key = "";
    this.private_api_key = "";
    this.mode = exports.IntegrationMode.CRM;
    this.company_types_to_import = new Array();
    this.contact_types_to_import = new Array();
    this.company_markets_to_import = new Array();
    this.opportunity_owners_to_import = new Array();
    this.opportunity_stages_to_import = new Array();
    this.opportunity_status_to_import = new Array();
    this.opportunity_updated_at_filter = null;
    this.default_values = new ConnectwiseDefaultValues();
    this.stage_mapping = new Array();
    this.product_mapping = new Array();
    this.generate_fields = false;
    this.document_push_filter = false;
    this.is_item_custom_fields_non_editable = false;
    this.product_sync_details = null;
    this.product_export_mode = null;
    this.tax_mapping = new Array();
    this.product_field_mapping = new CWProductFieldMapping();
    this.integrated_pipeline_id = "";
    this.unit_of_measure_mapping = new Array();
    this.use_stage_mapping_only = false;
    this.opportunity_sync_info = new OpportunitySyncInfo();
    this.should_push_closed_opportunity = false;
    this.item_custom_field_mapping = [];
    this.client_user_custom_field_mapping = [];
    this.client_company_custom_field_mapping = [];
    this.opportunity_custom_field_mapping = [];
    this.import_client_company_with_at_least_one_opportunity = false;
  }
  return AddConnectWiseIntegrationRequest;
}();
var DeleteZapierIntegrationRequest = /** @class */function () {
  function DeleteZapierIntegrationRequest() {
    this.zapier = new ZapierIntegration();
  }
  return DeleteZapierIntegrationRequest;
}();
var AddIngramIntegrationRequest = /** @class */function () {
  function AddIngramIntegrationRequest() {
    this.cred_type = exports.IngramCredentialType.XML;
  }
  return AddIngramIntegrationRequest;
}();
var AddCBIntegrationRequest = /** @class */function () {
  function AddCBIntegrationRequest() {}
  return AddCBIntegrationRequest;
}();
var AddTechDataIntegrationRequest = /** @class */function () {
  function AddTechDataIntegrationRequest() {}
  return AddTechDataIntegrationRequest;
}();
var AddDAndHIntegrationRequest = /** @class */function () {
  function AddDAndHIntegrationRequest() {}
  return AddDAndHIntegrationRequest;
}();
var AddSynnexIntegrationRequest = /** @class */function () {
  function AddSynnexIntegrationRequest() {}
  return AddSynnexIntegrationRequest;
}();
var AddWestcoastIntegrationRequest = /** @class */function () {
  function AddWestcoastIntegrationRequest() {
    this.login_id = "";
    this.password = "";
    this.company_code = "";
    this.region = exports.DistributorRegions.UK;
  }
  return AddWestcoastIntegrationRequest;
}();
exports.CustomFieldType = void 0;
(function (CustomFieldType) {
  CustomFieldType["TextField"] = "text_field";
  CustomFieldType["NumberField"] = "number_field";
  CustomFieldType["Dropdown"] = "dropdown_field";
  CustomFieldType["DecimalField"] = "decimal_field";
  CustomFieldType["SystemField"] = "system_field";
  CustomFieldType["DateField"] = "date_field";
  CustomFieldType["DropdownFieldWithKeyValue"] = "dropdown_field_with_key_value";
  CustomFieldType["CurrencyField"] = "currency_field";
  CustomFieldType["AmountField"] = "amount_field";
  CustomFieldType["FormulaField"] = "formula_field";
})(exports.CustomFieldType || (exports.CustomFieldType = {}));
var ZmToCwCustomFieldTypeMap = (_b = {}, _b[exports.CustomFieldType.DateField] = [exports.ConnectwiseCustomFieldType.Date], _b[exports.CustomFieldType.TextField] = [exports.ConnectwiseCustomFieldType.Button, exports.ConnectwiseCustomFieldType.Checkbox, exports.ConnectwiseCustomFieldType.Hyperlink, exports.ConnectwiseCustomFieldType.Text, exports.ConnectwiseCustomFieldType.TextArea], _b[exports.CustomFieldType.NumberField] = [exports.ConnectwiseCustomFieldType.Number, exports.ConnectwiseCustomFieldType.Percent], _b[exports.CustomFieldType.DecimalField] = [exports.ConnectwiseCustomFieldType.Number, exports.ConnectwiseCustomFieldType.Percent], _b[exports.CustomFieldType.AmountField] = [exports.ConnectwiseCustomFieldType.Number, exports.ConnectwiseCustomFieldType.Percent], _b[exports.CustomFieldType.Dropdown] = [], _b[exports.CustomFieldType.SystemField] = [], _b[exports.CustomFieldType.DropdownFieldWithKeyValue] = [], _b[exports.CustomFieldType.CurrencyField] = [], _b[exports.CustomFieldType.FormulaField] = [], _b);
var ZmToATCustomFieldTypeMap = (_c = {}, _c[exports.CustomFieldType.DateField] = [exports.AutotaskCustomFieldType.Date], _c[exports.CustomFieldType.TextField] = [exports.AutotaskCustomFieldType.String], _c[exports.CustomFieldType.NumberField] = [exports.AutotaskCustomFieldType.Long, exports.AutotaskCustomFieldType.Integer, exports.AutotaskCustomFieldType.Short], _c[exports.CustomFieldType.DecimalField] = [exports.AutotaskCustomFieldType.Decimal, exports.AutotaskCustomFieldType.Double], _c[exports.CustomFieldType.AmountField] = [exports.AutotaskCustomFieldType.Decimal, exports.AutotaskCustomFieldType.Double], _c[exports.CustomFieldType.Dropdown] = [], _c[exports.CustomFieldType.SystemField] = [], _c[exports.CustomFieldType.DropdownFieldWithKeyValue] = [], _c[exports.CustomFieldType.CurrencyField] = [], _c[exports.CustomFieldType.FormulaField] = [], _c);
var DynamicPricingDistributors = /** @class */function () {
  function DynamicPricingDistributors() {
    this.distributor = index.Distributor.Ingram;
    this.selected_warehouse = null;
  }
  return DynamicPricingDistributors;
}();
exports.DescimalRountingPrecision = void 0;
(function (DescimalRountingPrecision) {
  DescimalRountingPrecision[DescimalRountingPrecision["Hundreds"] = 2] = "Hundreds";
  DescimalRountingPrecision[DescimalRountingPrecision["Tens"] = 1] = "Tens";
})(exports.DescimalRountingPrecision || (exports.DescimalRountingPrecision = {}));
exports.WholeNumberRountingPrecision = void 0;
(function (WholeNumberRountingPrecision) {
  WholeNumberRountingPrecision[WholeNumberRountingPrecision["Ones"] = 0] = "Ones";
  WholeNumberRountingPrecision[WholeNumberRountingPrecision["Tens"] = -1] = "Tens";
  WholeNumberRountingPrecision[WholeNumberRountingPrecision["Hundreds"] = -2] = "Hundreds";
  WholeNumberRountingPrecision[WholeNumberRountingPrecision["Thousands"] = -3] = "Thousands";
  WholeNumberRountingPrecision[WholeNumberRountingPrecision["Millions"] = -6] = "Millions";
})(exports.WholeNumberRountingPrecision || (exports.WholeNumberRountingPrecision = {}));
exports.RoundingType = void 0;
(function (RoundingType) {
  RoundingType["Nearest"] = "nearest";
  RoundingType["HalfUp"] = "half_up";
  RoundingType["HalfDown"] = "half_down";
  RoundingType["Ceiling"] = "ceiling";
  RoundingType["Floor"] = "floor";
  RoundingType["HalfToEven"] = "half_to_even";
  RoundingType["HalfToOdd"] = "half_to_odd";
  RoundingType["HalfAwayFromZero"] = "half_away_from_zero";
  RoundingType["HalfTowardsZero"] = "half_towards_zero";
})(exports.RoundingType || (exports.RoundingType = {}));
var RoundingDetails = /** @class */function () {
  function RoundingDetails() {
    this.precision = exports.WholeNumberRountingPrecision.Ones;
    this.is_enabled = false;
    this.method = exports.RoundingType.Nearest;
  }
  return RoundingDetails;
}();
var AutoPriceUpdateDetails = /** @class */function () {
  function AutoPriceUpdateDetails() {
    this.is_enabled = false;
    this.selected_distributors = new Array();
    this.rounding_details = new RoundingDetails();
  }
  return AutoPriceUpdateDetails;
}();
var CustomFieldCount = /** @class */function () {
  function CustomFieldCount() {
    this[_f] = 0;
    this[_g] = 0;
    this[_h] = 0;
    this[_j] = 0;
    this[_k] = 0;
    this[_l] = 0;
  }
  return CustomFieldCount;
}();
_f = common.ZomentumEntities.Opportunity, _g = common.ZomentumEntities.Client, _h = common.ZomentumEntities.ClientContact, _j = common.ZomentumEntities.Product, _k = common.ZomentumEntities.Document, _l = common.ZomentumEntities.VendorPartner;
var CustomFieldCountPartnerAlign = /** @class */function () {
  function CustomFieldCountPartnerAlign() {
    this[_m] = 0;
    this[_o] = 0;
    this[_p] = 0;
    this[_q] = 0;
    this[_r] = 0;
    this[_s] = 0;
  }
  return CustomFieldCountPartnerAlign;
}();
_m = common.ZomentumEntities.VendorOpportunity, _o = common.ZomentumEntities.VendorClientContact, _p = common.ZomentumEntities.VendorClient, _q = common.ZomentumEntities.MarketplaceSKU, _r = common.ZomentumEntities.VendorPartner, _s = common.ZomentumEntities.SalesLogCall;
exports.CustomFieldMandatoryFieldDetailsValues = void 0;
(function (CustomFieldMandatoryFieldDetailsValues) {
  CustomFieldMandatoryFieldDetailsValues["FOR_UI"] = "for_ui";
  CustomFieldMandatoryFieldDetailsValues["FOR_CSV"] = "for_csv";
  CustomFieldMandatoryFieldDetailsValues["FOR_ZAPIER"] = "for_zapier";
})(exports.CustomFieldMandatoryFieldDetailsValues || (exports.CustomFieldMandatoryFieldDetailsValues = {}));
exports.DKIMStatus = void 0;
(function (DKIMStatus) {
  DKIMStatus["Pending"] = "pending";
  DKIMStatus["Success"] = "success";
  DKIMStatus["Failed"] = "failed";
  DKIMStatus["TemporaryFailed"] = "temporary_failure";
  DKIMStatus["NotStarted"] = "not_started";
})(exports.DKIMStatus || (exports.DKIMStatus = {}));
exports.DatePatterns = void 0;
(function (DatePatterns) {
  DatePatterns["MM_SLASH_DD_SLASH_YYYY"] = "MM/dd/yyyy";
  DatePatterns["DD_SLASH_MM_SLASH_YYYY"] = "dd/MM/yyyy";
  DatePatterns["DD_HYPHEN_MMM_COMMA_YYYY"] = "dd-MMM, yyyy";
  DatePatterns["YYYY_SLASH_MM_SLASH_DD"] = "yyyy/MM/dd";
  DatePatterns["MMM_HYPHEN_DD_COMMA_YYYY"] = "MMM-dd, yyyy";
})(exports.DatePatterns || (exports.DatePatterns = {}));
var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
exports.KaseyaURL = void 0;
(function (KaseyaURL) {
  KaseyaURL["bms"] = "bms";
  KaseyaURL["bmsemea"] = "bmsemea";
  KaseyaURL["bmsapac"] = "bmsapac";
  KaseyaURL["custom_url"] = "custom_url";
})(exports.KaseyaURL || (exports.KaseyaURL = {}));
var KaseyaURLHost = (_d = {}, _d[exports.KaseyaURL.bms] = "https://bms.kaseya.com/Login.aspx", _d[exports.KaseyaURL.bmsemea] = "https://bmsemea.kaseya.com/Login.aspx", _d[exports.KaseyaURL.bmsapac] = "https://bmsapac.kaseya.com/Login.aspx", _d);
var StringifiedLabelValuePair = /** @class */function () {
  function StringifiedLabelValuePair(label, value) {
    this.label = 0;
    this.value = "";
    this.label = label;
    this.value = value;
  }
  return StringifiedLabelValuePair;
}();
var KaseyaCredentialsTestRequest = /** @class */function () {
  function KaseyaCredentialsTestRequest(args) {
    this.server_endpoint = "";
    this.company = "";
    this.username = "";
    this.password = "";
    this.server_endpoint = args.server_endpoint;
    this.company = args.company;
    this.username = args.username;
    this.password = args.password;
  }
  return KaseyaCredentialsTestRequest;
}();
var KaseyaSettingsRequest = /** @class */function () {
  function KaseyaSettingsRequest() {
    this.mode = exports.IntegrationMode.CRM;
    this.two_way_sync = false;
    this.push_quotations = false;
    this.pull_updates = false;
    this.product_sync_detail = null;
    this.default_values = new KaseyaDefaultValues();
  }
  return KaseyaSettingsRequest;
}();
var KaseyaFieldMappingRequest = /** @class */function () {
  function KaseyaFieldMappingRequest() {}
  return KaseyaFieldMappingRequest;
}();
var KaseyaDefaultValues = /** @class */function () {
  function KaseyaDefaultValues() {}
  return KaseyaDefaultValues;
}();
exports.KaseyaPushBundleLevelOption = void 0;
(function (KaseyaPushBundleLevelOption) {
  KaseyaPushBundleLevelOption["PUSH_AS_SINGLE_SERVICE"] = "push_as_single_service";
  KaseyaPushBundleLevelOption["PUSH_AS_SERVICE_WITH_CHILD_ITEM_ZERO_PRICE"] = "push_as_service_with_child_item_zero_price";
  KaseyaPushBundleLevelOption["INDIVIDUAL_CHILD_ITEM_WITH_BUNDLE_PRICE_EQUALLY_DIVIDED"] = "individual_child_item_with_bundle_price_equally_divided";
})(exports.KaseyaPushBundleLevelOption || (exports.KaseyaPushBundleLevelOption = {}));
exports.DickerDataConnectionOptions = void 0;
(function (DickerDataConnectionOptions) {
  DickerDataConnectionOptions["API"] = "API Feed";
  DickerDataConnectionOptions["CSV"] = "CSV Feed";
})(exports.DickerDataConnectionOptions || (exports.DickerDataConnectionOptions = {}));
exports.DickerDataDistributionType = void 0;
(function (DickerDataDistributionType) {
  DickerDataDistributionType["API"] = "via_api";
  DickerDataDistributionType["CSV"] = "via_url";
})(exports.DickerDataDistributionType || (exports.DickerDataDistributionType = {}));
var RepairShoprCredentialsTestRequest = /** @class */function () {
  function RepairShoprCredentialsTestRequest(args) {
    this.api_key = "";
    this.sub_domain = "";
    this.api_key = args.api_key;
    this.sub_domain = args.sub_domain;
  }
  return RepairShoprCredentialsTestRequest;
}();
var RepairShoprSettingsRequest = /** @class */function () {
  function RepairShoprSettingsRequest() {
    this.sync_option = exports.SyncOption.ExportOnly;
    this.should_import_client_hierarchy = false;
    this.should_split_line_items = false;
  }
  return RepairShoprSettingsRequest;
}();
var RepairShoprFieldMappingRequest = /** @class */function () {
  function RepairShoprFieldMappingRequest() {
    this.product_mapping = new Array();
    this.generate_fields = false;
  }
  return RepairShoprFieldMappingRequest;
}();
var StringifyRepairShoprProduct = /** @class */function () {
  function StringifyRepairShoprProduct(args) {
    this.id = args.id;
    this.account_id = args.account_id;
    this.ancestry = args.ancestry;
    this.name = args.name;
    this.description = args.description;
    this.device_product_id = args.device_product_id;
    this.names_depth_cache = args.names_depth_cache;
  }
  return StringifyRepairShoprProduct;
}();
var AddRepairShoprIntegrationRequest = /** @class */function () {
  function AddRepairShoprIntegrationRequest() {
    this.client_created_at_filter = null;
    this.product_category_to_import = null;
    this.product_mapping = new Array();
    this.generate_product_category_custom_field = false;
  }
  return AddRepairShoprIntegrationRequest;
}();
exports.HubspotEntities = void 0;
(function (HubspotEntities) {
  HubspotEntities["COMPANY"] = "company";
  HubspotEntities["DEAL"] = "deal";
  HubspotEntities["CONTACT"] = "contact";
  HubspotEntities["PARTNER"] = "partner";
  HubspotEntities["CLIENT"] = "client";
})(exports.HubspotEntities || (exports.HubspotEntities = {}));
exports.HubspotActivities = void 0;
(function (HubspotActivities) {
  HubspotActivities["NOTES"] = "notes";
  HubspotActivities["CALLS"] = "calls";
  HubspotActivities["TASKS"] = "tasks";
})(exports.HubspotActivities || (exports.HubspotActivities = {}));
exports.HubspotPushModes = void 0;
(function (HubspotPushModes) {
  HubspotPushModes["DONT_PUSH"] = "dont_push";
  HubspotPushModes["WHEN_OPPORTUNITY_CREATE_OR_STAGE_UPDATED"] = "when_opportunity_create_or_stage_updated";
  HubspotPushModes["WHEN_OPPORTUNITY_MOVED_TO_TERMINAL_STAGE"] = "when_opportunity_moved_to_terminal_stage";
})(exports.HubspotPushModes || (exports.HubspotPushModes = {}));
exports.HubspotCompanyPullFiltersFormKeys = void 0;
(function (HubspotCompanyPullFiltersFormKeys) {
  HubspotCompanyPullFiltersFormKeys["HubspotCompanyPullFilters"] = "hubspot_company_pull_filters";
  HubspotCompanyPullFiltersFormKeys["CompanyWithListId"] = "company_with_list_id";
  HubspotCompanyPullFiltersFormKeys["CompanyWithListIdCheckbox"] = "company_with_list_id_checkbox";
  HubspotCompanyPullFiltersFormKeys["CompanyWithLifecycleStage"] = "company_with_lifecycle_stage";
  HubspotCompanyPullFiltersFormKeys["CompanyWithLifecycleStageCheckbox"] = "company_with_lifecycle_stage_checkbox";
  HubspotCompanyPullFiltersFormKeys["CompanyWithLeadStatus"] = "company_with_lead_status";
  HubspotCompanyPullFiltersFormKeys["CompanyWithLeadStatusCheckbox"] = "company_with_lead_status_checkbox";
  HubspotCompanyPullFiltersFormKeys["CompanyWithType"] = "company_with_type";
  HubspotCompanyPullFiltersFormKeys["CompanyWithTypeCheckbox"] = "company_with_type_checkbox";
})(exports.HubspotCompanyPullFiltersFormKeys || (exports.HubspotCompanyPullFiltersFormKeys = {}));
exports.HubspotDealPullFiltersFormKeys = void 0;
(function (HubspotDealPullFiltersFormKeys) {
  HubspotDealPullFiltersFormKeys["HubspotDealPullFilters"] = "hubspot_deal_pull_filters";
  HubspotDealPullFiltersFormKeys["DealWithOwnerId"] = "deal_with_owner_id";
  HubspotDealPullFiltersFormKeys["DealWithOwnerIdCheckbox"] = "deal_with_owner_id_checkbox";
  HubspotDealPullFiltersFormKeys["DealWithStageId"] = "deal_with_stage_id";
  HubspotDealPullFiltersFormKeys["DealWithStageIdCheckbox"] = "deal_with_stage_id_checkbox";
  HubspotDealPullFiltersFormKeys["DealWithType"] = "deal_with_type";
  HubspotDealPullFiltersFormKeys["DealWithTypeCheckbox"] = "deal_with_type_checkbox";
})(exports.HubspotDealPullFiltersFormKeys || (exports.HubspotDealPullFiltersFormKeys = {}));
var HubspotOpportunityPushSettings = /** @class */function () {
  function HubspotOpportunityPushSettings() {
    this.when_opportunity_created = false;
    this.when_opportunity_moved_to_terminal_stage = false;
  }
  return HubspotOpportunityPushSettings;
}();
var HubspotClientPushSettings = /** @class */function () {
  function HubspotClientPushSettings() {
    this.when_client_created = false;
    this.when_opportunity_getting_pushed = false;
  }
  return HubspotClientPushSettings;
}();
var HubspotPushSettingsRequest = /** @class */function () {
  function HubspotPushSettingsRequest() {
    this.when_opportunity_created_or_stage_updated = false;
    this.when_opportunity_moved_to_terminal_stage = false;
    this.push_quote_as_attachment = undefined;
    this.opportunity_push_settings = null;
    this.client_push_settings = null;
  }
  HubspotPushSettingsRequest.isPushingClientAndOpportunity = function (clientPushSettings, opportunityPushSettings) {
    return !!(clientPushSettings === null || clientPushSettings === void 0 ? void 0 : clientPushSettings.when_client_created) || !!(clientPushSettings === null || clientPushSettings === void 0 ? void 0 : clientPushSettings.when_opportunity_getting_pushed) || !!(opportunityPushSettings === null || opportunityPushSettings === void 0 ? void 0 : opportunityPushSettings.when_opportunity_created) || !!(opportunityPushSettings === null || opportunityPushSettings === void 0 ? void 0 : opportunityPushSettings.when_opportunity_moved_to_terminal_stage);
  };
  return HubspotPushSettingsRequest;
}();
var HubspotCompanyPullFiltersRequest = /** @class */function () {
  function HubspotCompanyPullFiltersRequest() {
    this.company_with_list_id = null;
    this.company_with_lifecycle_stage = null;
    this.company_with_lead_status = null;
    this.company_with_type = null;
    this.company_with_deal_attached = false;
    this.company_with_partner_manager = false;
  }
  HubspotCompanyPullFiltersRequest.getHubspotCompanyPullRequest = function (companyFormValues) {
    var _a, _b, _c, _d, _e, _t;
    var hubspotCompanyPullRequest = new HubspotCompanyPullFiltersRequest();
    hubspotCompanyPullRequest.company_with_list_id = (companyFormValues === null || companyFormValues === void 0 ? void 0 : companyFormValues.company_with_list_id_checkbox) ? (_a = companyFormValues.company_with_list_id) !== null && _a !== void 0 ? _a : null : null;
    hubspotCompanyPullRequest.company_with_lifecycle_stage = (companyFormValues === null || companyFormValues === void 0 ? void 0 : companyFormValues.company_with_lifecycle_stage_checkbox) ? (_b = companyFormValues.company_with_lifecycle_stage) !== null && _b !== void 0 ? _b : null : null;
    hubspotCompanyPullRequest.company_with_lead_status = (companyFormValues === null || companyFormValues === void 0 ? void 0 : companyFormValues.company_with_lead_status_checkbox) ? (_c = companyFormValues.company_with_lead_status) !== null && _c !== void 0 ? _c : null : null;
    hubspotCompanyPullRequest.company_with_type = (companyFormValues === null || companyFormValues === void 0 ? void 0 : companyFormValues.company_with_type_checkbox) ? (_d = companyFormValues.company_with_type) !== null && _d !== void 0 ? _d : null : null;
    hubspotCompanyPullRequest.company_with_deal_attached = (_e = companyFormValues === null || companyFormValues === void 0 ? void 0 : companyFormValues.company_with_deal_attached) !== null && _e !== void 0 ? _e : false;
    hubspotCompanyPullRequest.company_with_partner_manager = (_t = companyFormValues === null || companyFormValues === void 0 ? void 0 : companyFormValues.company_with_partner_manager) !== null && _t !== void 0 ? _t : false;
    return hubspotCompanyPullRequest;
  };
  return HubspotCompanyPullFiltersRequest;
}();
var HubspotDealPullFiltersRequest = /** @class */function () {
  function HubspotDealPullFiltersRequest() {
    this.deal_with_owner_id = null;
    this.deal_with_stage_id = null;
    this.deal_with_type = null;
    this.deal_attached_with_company_or_contact = false;
    this.deal_with_partner_manager = false;
  }
  HubspotDealPullFiltersRequest.getHubspotDealPullRequest = function (dealFormValues) {
    var _a, _b, _c, _d, _e;
    var hubspotDealPullRequest = new HubspotDealPullFiltersRequest();
    hubspotDealPullRequest.deal_with_owner_id = (dealFormValues === null || dealFormValues === void 0 ? void 0 : dealFormValues.deal_with_owner_id_checkbox) ? (_a = dealFormValues.deal_with_owner_id) !== null && _a !== void 0 ? _a : null : null;
    hubspotDealPullRequest.deal_with_stage_id = (dealFormValues === null || dealFormValues === void 0 ? void 0 : dealFormValues.deal_with_stage_id_checkbox) ? (_b = dealFormValues.deal_with_stage_id) !== null && _b !== void 0 ? _b : null : null;
    hubspotDealPullRequest.deal_with_type = (dealFormValues === null || dealFormValues === void 0 ? void 0 : dealFormValues.deal_with_type_checkbox) ? (_c = dealFormValues.deal_with_type) !== null && _c !== void 0 ? _c : null : null;
    hubspotDealPullRequest.deal_attached_with_company_or_contact = (_d = dealFormValues === null || dealFormValues === void 0 ? void 0 : dealFormValues.deal_attached_with_company_or_contact) !== null && _d !== void 0 ? _d : false;
    hubspotDealPullRequest.deal_with_partner_manager = (_e = dealFormValues === null || dealFormValues === void 0 ? void 0 : dealFormValues.deal_with_partner_manager) !== null && _e !== void 0 ? _e : false;
    return hubspotDealPullRequest;
  };
  return HubspotDealPullFiltersRequest;
}();
var HubspotContactPullFiltersRequest = /** @class */function () {
  function HubspotContactPullFiltersRequest() {
    this.contact_with_list_id = null;
    this.contact_with_lifecycle_stage = null;
    this.contact_with_lead_status = null;
    this.contact_attached_with_company_or_deal = false;
  }
  HubspotContactPullFiltersRequest.getHubspotContactPullRequest = function (contactFormValues) {
    var _a, _b, _c, _d;
    var hubspotContactPullRequest = new HubspotContactPullFiltersRequest();
    hubspotContactPullRequest.contact_with_list_id = (contactFormValues === null || contactFormValues === void 0 ? void 0 : contactFormValues.contact_with_list_id_checkbox) ? (_a = contactFormValues.contact_with_list_id) !== null && _a !== void 0 ? _a : null : null;
    hubspotContactPullRequest.contact_with_lifecycle_stage = (contactFormValues === null || contactFormValues === void 0 ? void 0 : contactFormValues.contact_with_lifecycle_stage_checkbox) ? (_b = contactFormValues.contact_with_lifecycle_stage) !== null && _b !== void 0 ? _b : null : null;
    hubspotContactPullRequest.contact_with_lead_status = (contactFormValues === null || contactFormValues === void 0 ? void 0 : contactFormValues.contact_with_lead_status_checkbox) ? (_c = contactFormValues.contact_with_lead_status) !== null && _c !== void 0 ? _c : null : null;
    hubspotContactPullRequest.contact_attached_with_company_or_deal = (_d = contactFormValues === null || contactFormValues === void 0 ? void 0 : contactFormValues.contact_attached_with_company_or_deal) !== null && _d !== void 0 ? _d : false;
    return hubspotContactPullRequest;
  };
  return HubspotContactPullFiltersRequest;
}();
var HubspotDefaultPullEntities = /** @class */function () {
  function HubspotDefaultPullEntities() {
    this.default_client_company_for_deal = null;
    this.default_client_company_for_contact = null;
    this.default_owner_for_company = null;
    this.default_owner_for_deal = null;
    this.default_owner_for_activities = null;
    this.default_hubspot_pipeline_details = {
      hubspot_pipeline_id: "",
      hubspot_stage_id: ""
    };
    this.default_zomentum_pipeline_details = {
      zomentum_pipeline_id: "",
      zomentum_stage_id: ""
    };
  }
  HubspotDefaultPullEntities.getHubspotDefaultPullEntitiesRequest = function (defaultFormValues) {
    var _a, _b, _c, _d, _e, _t, _u;
    var hubspotDefaultPullEntitiesRequest = new HubspotDefaultPullEntities();
    hubspotDefaultPullEntitiesRequest.default_client_company_for_deal = (_a = defaultFormValues === null || defaultFormValues === void 0 ? void 0 : defaultFormValues.default_client_company_for_deal) !== null && _a !== void 0 ? _a : null;
    hubspotDefaultPullEntitiesRequest.default_client_company_for_contact = (_b = defaultFormValues === null || defaultFormValues === void 0 ? void 0 : defaultFormValues.default_client_company_for_contact) !== null && _b !== void 0 ? _b : null;
    hubspotDefaultPullEntitiesRequest.default_owner_for_company = (_c = defaultFormValues === null || defaultFormValues === void 0 ? void 0 : defaultFormValues.default_owner_for_company) !== null && _c !== void 0 ? _c : null;
    hubspotDefaultPullEntitiesRequest.default_owner_for_deal = (_d = defaultFormValues === null || defaultFormValues === void 0 ? void 0 : defaultFormValues.default_owner_for_deal) !== null && _d !== void 0 ? _d : null;
    hubspotDefaultPullEntitiesRequest.default_owner_for_activities = (_e = defaultFormValues === null || defaultFormValues === void 0 ? void 0 : defaultFormValues.default_owner_for_activities) !== null && _e !== void 0 ? _e : null;
    hubspotDefaultPullEntitiesRequest.default_hubspot_pipeline_details = (_t = defaultFormValues === null || defaultFormValues === void 0 ? void 0 : defaultFormValues.default_hubspot_pipeline_details) !== null && _t !== void 0 ? _t : null;
    hubspotDefaultPullEntitiesRequest.default_zomentum_pipeline_details = (_u = defaultFormValues === null || defaultFormValues === void 0 ? void 0 : defaultFormValues.default_zomentum_pipeline_details) !== null && _u !== void 0 ? _u : null;
    return hubspotDefaultPullEntitiesRequest;
  };
  return HubspotDefaultPullEntities;
}();
var HubspotPullSettingsRequest = /** @class */function () {
  function HubspotPullSettingsRequest() {
    this.pull_deal = false;
    this.pull_contact = false;
    this.pull_note = false;
    this.pull_call = false;
    this.pull_task = false;
    this.hubspot_company_pull_filters = new HubspotCompanyPullFiltersRequest();
    this.hubspot_deal_pull_filters = null;
    this.hubspot_contact_pull_filters = null;
    this.hubspot_default_pull_entities = new HubspotDefaultPullEntities();
  }
  return HubspotPullSettingsRequest;
}();
var VendorHubspotPullSettingsRequest = /** @class */function () {
  function VendorHubspotPullSettingsRequest() {
    this.pull_deal = false;
    this.pull_partner_contact = false;
    this.pull_client_contact = false;
    this.pull_note = true;
    this.pull_call = false;
    this.pull_task = false;
    this.hubspot_client_company_pull_filters = new HubspotCompanyPullFiltersRequest();
    this.hubspot_client_contact_pull_filters = null;
    this.hubspot_deal_pull_filters = null;
    this.hubspot_default_pull_entities = new HubspotDefaultPullEntities();
  }
  return VendorHubspotPullSettingsRequest;
}();
var HubspotStageDetails = /** @class */function () {
  function HubspotStageDetails(id, label) {
    this.hubspot_stage_id = "";
    this.label = "";
    this.hubspot_stage_id = String(id);
    this.label = label;
  }
  return HubspotStageDetails;
}();
var ZomentumStageDetails = /** @class */function () {
  function ZomentumStageDetails(id, label) {
    this.zomentum_stage_id = "";
    this.label = "";
    this.zomentum_stage_id = String(id);
    this.label = label;
  }
  return ZomentumStageDetails;
}();
var HubspotPipelineStageMappingRequest = /** @class */function () {
  function HubspotPipelineStageMappingRequest() {
    this.hubspot_pipeline_id = "";
    this.zomentum_stage_details = new ZomentumStageDetails("", "");
    this.hubspot_stage_details = new HubspotStageDetails("", "");
  }
  return HubspotPipelineStageMappingRequest;
}();
var HubspotOpportunityPipelineMappingRequest = /** @class */function () {
  function HubspotOpportunityPipelineMappingRequest() {
    this.zomentum_pipeline_id = "";
    this.hubspot_pipeline_stage_mapping = new Array();
  }
  return HubspotOpportunityPipelineMappingRequest;
}();
var AddHubspotIntegrationRequest = /** @class */function () {
  function AddHubspotIntegrationRequest() {
    this.hubspot_push_settings = null;
    this.hubspot_pull_settings = null;
    this.hubspot_opportunity_pipeline_mapping = new Array();
    this.opportunity_field_mapping = new Array();
    this.client_company_field_mapping = new Array();
    this.client_user_field_mapping = new Array();
  }
  return AddHubspotIntegrationRequest;
}();
var AddVendorHubspotIntegrationRequest = /** @class */function () {
  function AddVendorHubspotIntegrationRequest() {
    this.hubspot_push_settings = null;
    this.hubspot_pull_settings = null;
    this.hubspot_opportunity_pipeline_mapping = new Array();
    this.opportunity_field_mapping = new Array();
    this.client_company_field_mapping = new Array();
    this.client_user_field_mapping = new Array();
  }
  return AddVendorHubspotIntegrationRequest;
}();
var HubspotIntegration = /** @class */function (_super) {
  tslib_es6.__extends(HubspotIntegration, _super);
  function HubspotIntegration() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.connected_user = "";
    _this.hubspot_user_id = "";
    _this.hubspot_hub_id = "";
    _this.hubspot_app_id = "";
    _this.is_initial_sync_pending = false;
    return _this;
  }
  return HubspotIntegration;
}(AddHubspotIntegrationRequest);
var VendorHubspotIntegration = /** @class */function (_super) {
  tslib_es6.__extends(VendorHubspotIntegration, _super);
  function VendorHubspotIntegration() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.is_initial_sync_pending = false;
    _this.connected_user = "";
    return _this;
  }
  return VendorHubspotIntegration;
}(AddVendorHubspotIntegrationRequest);
var hubspotSelectedLifecycleStagesIds = ["opportunity", "customer", "salesqualifiedlead", "evangelist"];
var hubspotSelectedCompanyIds = ["PROSPECT", "VENDOR"];
var QuickbooksIntegration = /** @class */function () {
  function QuickbooksIntegration() {
    this.connected_user = "";
    this.tax_mappings = null;
    this.default_values = null;
    this.convert_estimates_to_invoices_on_document_won = true;
    this.is_initial_sync_pending = false;
  }
  return QuickbooksIntegration;
}();
var QuickbooksConnectedUser = /** @class */function () {
  function QuickbooksConnectedUser() {
    this.connected_user = "";
    this.tax_mappings = new Array();
    this.default_values = null;
    this.sync_quotes = false;
    this.sync_payments = false;
    this.convert_estimates_to_invoices_on_document_won = false;
    this.zm_item_type_and_billing_period_to_qbo_account_mappings = new Array();
    this.allowed_zm_payment_mode = [];
    this.zm_payment_mode_to_qbo_payment_method_mappings = [];
    this.zm_payment_mode_to_qbo_deposit_account_mappings = [];
  }
  return QuickbooksConnectedUser;
}();
var generateProductTypeToQuickbooksAccountMappingRequest = function generateProductTypeToQuickbooksAccountMappingRequest(income_account_mapping) {
  try {
    var productTypeToQuickbooksAccountMappingRequest = new Array();
    for (var productType in income_account_mapping) {
      var mappings = new Array();
      var incomeAccountMappingAtProductType = income_account_mapping[productType];
      for (var billingPeriod in incomeAccountMappingAtProductType) {
        var selectedAccount = incomeAccountMappingAtProductType[billingPeriod];
        if (selectedAccount) {
          mappings.push({
            is_recurring: billingPeriod === exports.QuickbooksProductBillingPeriodForMapping.Recurring,
            income_account: JSON.parse(selectedAccount)
          });
        }
      }
      !!mappings.length && productTypeToQuickbooksAccountMappingRequest.push({
        item_type: productType,
        mappings: mappings
      });
    }
    return productTypeToQuickbooksAccountMappingRequest;
  } catch (error) {
    console.error("Error in generating product type to quickbooks account mapping request", error);
    return [];
  }
};
var generateZMPaymentModeToQuickbooksMappingRequest = function generateZMPaymentModeToQuickbooksMappingRequest(mapping, isPaymentMethodMapping) {
  if (isPaymentMethodMapping === void 0) {
    isPaymentMethodMapping = true;
  }
  var zmPaymentModeToQuickbooksPaymentMappingRequest = new Array();
  var zmPaymentModeToQuickbooksDepositAccountMappingRequest = new Array();
  try {
    for (var gateway in mapping) {
      var paymentMethodMapping = mapping[gateway];
      for (var paymentMethod in paymentMethodMapping) {
        var selectedAccount = paymentMethodMapping[paymentMethod];
        if (selectedAccount) {
          var zmPaymentMode = {
            gateway: gateway,
            payment_method: paymentMethod
          };
          var parsedSelectedAccount = JSON.parse(selectedAccount);
          if (isPaymentMethodMapping) {
            zmPaymentModeToQuickbooksPaymentMappingRequest.push({
              zm_payment_mode: zmPaymentMode,
              qbo_payment_method: parsedSelectedAccount
            });
          } else {
            zmPaymentModeToQuickbooksDepositAccountMappingRequest.push({
              zm_payment_mode: zmPaymentMode,
              qbo_deposit_account: parsedSelectedAccount
            });
          }
        }
      }
    }
    return isPaymentMethodMapping ? zmPaymentModeToQuickbooksPaymentMappingRequest : zmPaymentModeToQuickbooksDepositAccountMappingRequest;
  } catch (error) {
    console.error("Error in generating payment mode to quickbooks payment method mapping", error);
    return [];
  }
};
var AddQuickbooksIntegrationRequest = /** @class */function () {
  function AddQuickbooksIntegrationRequest() {
    this.tax_mappings = new Array();
    this.default_values = null;
    this.convert_estimates_to_invoices_on_document_won = true;
    this.sync_quotes = false;
    this.sync_payments = false;
    this.zm_item_type_and_billing_period_to_qbo_account_mappings = new Array();
    this.zm_payment_mode_to_qbo_payment_method_mappings = [];
    this.zm_payment_mode_to_qbo_deposit_account_mappings = [];
  }
  return AddQuickbooksIntegrationRequest;
}();
exports.QuickbooksProductBillingPeriodForMapping = void 0;
(function (QuickbooksProductBillingPeriodForMapping) {
  QuickbooksProductBillingPeriodForMapping["OneTime"] = "one_time";
  QuickbooksProductBillingPeriodForMapping["Recurring"] = "recurring";
})(exports.QuickbooksProductBillingPeriodForMapping || (exports.QuickbooksProductBillingPeriodForMapping = {}));
var QuickbooksProductBillingPeriodForMappingValues = Object.values(exports.QuickbooksProductBillingPeriodForMapping);
var ProductTypeToBillingPeriodMap = (_e = {}, _e[index.ProductType.Hardware] = QuickbooksProductBillingPeriodForMappingValues, _e[index.ProductType.Software] = QuickbooksProductBillingPeriodForMappingValues, _e[index.ProductType.Service] = QuickbooksProductBillingPeriodForMappingValues, _e[index.ProductType.Labor] = QuickbooksProductBillingPeriodForMappingValues, _e[index.ProductType.Other] = QuickbooksProductBillingPeriodForMappingValues, _e);
exports.AvailablePaymentMethods = void 0;
(function (AvailablePaymentMethods) {
  AvailablePaymentMethods["ACH"] = "ach";
  AvailablePaymentMethods["CREDIT_CARD"] = "credit_card";
})(exports.AvailablePaymentMethods || (exports.AvailablePaymentMethods = {}));
var OrderFormCustomFields = /** @class */function () {
  function OrderFormCustomFields() {
    this.data = [{
      id: "8df5e7307e82449c82967c759d4cf0a3",
      created: new Date(),
      updated: new Date(),
      deleted: false,
      display_name: "Phone",
      custom_field_type: exports.CustomFieldType.NumberField,
      company_id: "a74d03effb21433ba372a6a5bd7f9dda",
      entity_type: common.ZomentumEntities.Client,
      allowed_dropdown_values: [],
      creator_user_id: "e47fd2a9acf649b798f6b4c9c4e1e518",
      values: [],
      system_field_id: "client_phone",
      is_editable_field: false,
      is_deletable_field: false,
      field_type: exports.CustomFieldType.NumberField,
      mandatory_field_details: null
    }, {
      id: "62f5a4cbd1964ffca1dcaee698319994",
      created: new Date(),
      updated: new Date(),
      deleted: false,
      display_name: "Billing Address",
      custom_field_type: exports.CustomFieldType.TextField,
      company_id: "a74d03effb21433ba372a6a5bd7f9dda",
      entity_type: common.ZomentumEntities.Client,
      allowed_dropdown_values: [],
      creator_user_id: "e47fd2a9acf649b798f6b4c9c4e1e518",
      values: [],
      system_field_id: "client_billing_address",
      is_editable_field: false,
      is_deletable_field: false,
      field_type: exports.CustomFieldType.TextField,
      mandatory_field_details: null
    }, {
      id: "fe3095011a474f468e1f0e5010c637d3",
      created: new Date(),
      updated: new Date(),
      deleted: false,
      display_name: "Fax",
      custom_field_type: exports.CustomFieldType.TextField,
      company_id: "a74d03effb21433ba372a6a5bd7f9dda",
      entity_type: common.ZomentumEntities.Client,
      allowed_dropdown_values: [],
      creator_user_id: "e47fd2a9acf649b798f6b4c9c4e1e518",
      values: [],
      system_field_id: "client_fax",
      is_editable_field: false,
      is_deletable_field: false,
      field_type: exports.CustomFieldType.TextField,
      mandatory_field_details: null
    }, {
      id: "332e6a4a158c4d2ea3a677ef7e5e9a90",
      created: new Date(),
      updated: new Date(),
      deleted: false,
      display_name: "Website",
      custom_field_type: exports.CustomFieldType.TextField,
      company_id: "a74d03effb21433ba372a6a5bd7f9dda",
      entity_type: common.ZomentumEntities.Client,
      allowed_dropdown_values: [],
      creator_user_id: "e47fd2a9acf649b798f6b4c9c4e1e518",
      values: [],
      system_field_id: "client_website",
      is_editable_field: false,
      is_deletable_field: false,
      field_type: exports.CustomFieldType.TextField,
      mandatory_field_details: null
    }, {
      id: "85e9c80f16af4d6cb45df9aa1be314c5",
      created: new Date(),
      updated: new Date(),
      deleted: false,
      display_name: "LinkedIn",
      custom_field_type: exports.CustomFieldType.TextField,
      company_id: "a74d03effb21433ba372a6a5bd7f9dda",
      entity_type: common.ZomentumEntities.Client,
      allowed_dropdown_values: [],
      creator_user_id: "e47fd2a9acf649b798f6b4c9c4e1e518",
      values: [],
      system_field_id: "client_linked_in_url",
      is_editable_field: false,
      is_deletable_field: false,
      field_type: exports.CustomFieldType.TextField,
      mandatory_field_details: null
    }, {
      id: "77a18a4c39f3464eb46fbf20f6777f28",
      created: new Date(),
      updated: new Date(),
      deleted: false,
      display_name: "Facebook",
      custom_field_type: exports.CustomFieldType.TextField,
      company_id: "a74d03effb21433ba372a6a5bd7f9dda",
      entity_type: common.ZomentumEntities.Client,
      allowed_dropdown_values: [],
      creator_user_id: "e47fd2a9acf649b798f6b4c9c4e1e518",
      values: [],
      system_field_id: "client_facebook_url",
      is_editable_field: false,
      is_deletable_field: false,
      field_type: exports.CustomFieldType.TextField,
      mandatory_field_details: null
    }, {
      id: "a2dc3b66168a4de4aa412d6d013ec67e",
      created: new Date(),
      updated: new Date(),
      deleted: false,
      display_name: "Twitter",
      custom_field_type: exports.CustomFieldType.TextField,
      company_id: "a74d03effb21433ba372a6a5bd7f9dda",
      entity_type: common.ZomentumEntities.Client,
      allowed_dropdown_values: [],
      creator_user_id: "e47fd2a9acf649b798f6b4c9c4e1e518",
      values: [],
      system_field_id: "client_twitter_url",
      is_editable_field: false,
      is_deletable_field: false,
      field_type: exports.CustomFieldType.TextField,
      mandatory_field_details: null
    }, {
      id: "6bd76cb7621641039ba3419c29981ae1",
      created: new Date(),
      updated: new Date(),
      deleted: false,
      display_name: "Market",
      custom_field_type: exports.CustomFieldType.Dropdown,
      company_id: "a74d03effb21433ba372a6a5bd7f9dda",
      entity_type: common.ZomentumEntities.Client,
      allowed_dropdown_values: [],
      creator_user_id: "e47fd2a9acf649b798f6b4c9c4e1e518",
      values: [],
      system_field_id: "client_market",
      is_editable_field: false,
      is_deletable_field: false,
      field_type: exports.CustomFieldType.Dropdown,
      mandatory_field_details: null
    }, {
      id: "05cafc0d96c8404791a40c53a46079d3",
      created: new Date(),
      updated: new Date(),
      deleted: false,
      display_name: "Company Types",
      custom_field_type: exports.CustomFieldType.Dropdown,
      company_id: "a74d03effb21433ba372a6a5bd7f9dda",
      entity_type: common.ZomentumEntities.Client,
      allowed_dropdown_values: [],
      creator_user_id: "e47fd2a9acf649b798f6b4c9c4e1e518",
      values: [],
      system_field_id: "client_company_types",
      is_editable_field: false,
      is_deletable_field: false,
      field_type: exports.CustomFieldType.Dropdown,
      mandatory_field_details: null
    }, {
      id: "cca0fbe8c142471084171a761c4255ba",
      created: new Date(),
      updated: new Date(),
      deleted: false,
      display_name: "Source",
      custom_field_type: exports.CustomFieldType.TextField,
      company_id: "a74d03effb21433ba372a6a5bd7f9dda",
      entity_type: common.ZomentumEntities.Client,
      allowed_dropdown_values: [],
      creator_user_id: "e47fd2a9acf649b798f6b4c9c4e1e518",
      values: [],
      system_field_id: "client_source",
      is_editable_field: false,
      is_deletable_field: false,
      field_type: exports.CustomFieldType.TextField,
      mandatory_field_details: null
    }, {
      id: "4bb0f775c34a469896fe2e118f69e591",
      created: new Date(),
      updated: new Date(),
      deleted: false,
      display_name: "Campaign",
      custom_field_type: exports.CustomFieldType.TextField,
      company_id: "a74d03effb21433ba372a6a5bd7f9dda",
      entity_type: common.ZomentumEntities.Client,
      allowed_dropdown_values: [],
      creator_user_id: "e47fd2a9acf649b798f6b4c9c4e1e518",
      values: [],
      system_field_id: "client_campaign",
      is_editable_field: false,
      is_deletable_field: false,
      field_type: exports.CustomFieldType.TextField,
      mandatory_field_details: null
    }, {
      id: "3d71e5d9a8104e12a5e4c029d21a9494",
      created: new Date(),
      updated: new Date(),
      deleted: false,
      display_name: "Date Acquired",
      custom_field_type: "date_field",
      company_id: "a74d03effb21433ba372a6a5bd7f9dda",
      entity_type: common.ZomentumEntities.Client,
      allowed_dropdown_values: [],
      creator_user_id: "e47fd2a9acf649b798f6b4c9c4e1e518",
      values: [],
      system_field_id: "client_date_acquired",
      is_editable_field: false,
      is_deletable_field: false,
      field_type: exports.CustomFieldType.DateField,
      mandatory_field_details: null
    }];
  }
  return OrderFormCustomFields;
}();
var SupplierCreateRequest = /** @class */function () {
  function SupplierCreateRequest(name, hasDropShip, email, address) {
    this.name = "";
    this.has_drop_ship = false;
    this.name = name;
    this.has_drop_ship = hasDropShip;
    this.email = !!(email === null || email === void 0 ? void 0 : email.length) ? email : null;
    this.address = undefined;
    if (address && !!Object.keys(address).length) {
      this.address = address;
    }
  }
  return SupplierCreateRequest;
}();
var SupplierUpdateRequest = /** @class */function () {
  function SupplierUpdateRequest(name, hasDropShip, email, address) {
    this.name = "";
    this.has_drop_ship = false;
    this.name = name;
    this.has_drop_ship = hasDropShip;
    this.address = address;
    this.email = !!(email === null || email === void 0 ? void 0 : email.length) ? email : null;
  }
  return SupplierUpdateRequest;
}();
exports.FormKeys = void 0;
(function (FormKeys) {
  FormKeys["AddEditPipeline"] = "add_edit_pipeline";
  FormKeys["AddEditTaxCategory"] = "add_edit_tax_category";
  FormKeys["AddEditTaxRegion"] = "add_edit_tax_region";
  FormKeys["AddEditUser"] = "add_edit_user";
  FormKeys["AddDomain"] = "add_domain";
  FormKeys["AddEmail"] = "add_email";
  FormKeys["AddEditSupplier"] = "add_edit_supplier";
  FormKeys["AddEditNote"] = "add_edit_note";
  FormKeys["AddEditCallLog"] = "add_edit_call_log";
  FormKeys["AddEditEmailLog"] = "add_edit_email_log";
  FormKeys["AddEditMeeting"] = "add_edit_meeting";
  FormKeys["AddEditTask"] = "add_edit_task";
  FormKeys["AddEditCloneProduct"] = "add_edit_clone_product";
  FormKeys["EditClient"] = "edit_client";
  FormKeys["AddClient"] = "add_client";
  FormKeys["EditCustomField"] = "edit_custom_field";
  FormKeys["AddCustomField"] = "add_custom_field";
  FormKeys["CreateDocument"] = "create_document";
  FormKeys["EditDocument"] = "edit_document";
  FormKeys["AddEtilizeProduct"] = "add_etilize_product";
  FormKeys["SendEmail"] = "send_email";
  FormKeys["EditOpportunity"] = "edit_opportunity";
  FormKeys["AddOpportunity"] = "add_opportunity";
  FormKeys["EditTemplate"] = "edit_template";
  FormKeys["AddOverrideTemplate"] = "add_override_template";
})(exports.FormKeys || (exports.FormKeys = {}));
exports.AmazonMarketPlaceURL = void 0;
(function (AmazonMarketPlaceURL) {
  AmazonMarketPlaceURL["US"] = "https://www.amazon.com";
  AmazonMarketPlaceURL["CA"] = "https://www.amazon.ca";
  AmazonMarketPlaceURL["ES"] = "https://www.amazon.es";
  AmazonMarketPlaceURL["UK"] = "https://www.amazon.co.uk";
  AmazonMarketPlaceURL["FR"] = "https://www.amazon.fr";
  AmazonMarketPlaceURL["DE"] = "https://www.amazon.de";
  AmazonMarketPlaceURL["IT"] = "https://www.amazon.it";
  AmazonMarketPlaceURL["JP"] = "https://www.amazon.co.jp";
})(exports.AmazonMarketPlaceURL || (exports.AmazonMarketPlaceURL = {}));
exports.AmazonLocale = void 0;
(function (AmazonLocale) {
  AmazonLocale["US"] = "en-US";
  AmazonLocale["UK"] = "en-GB";
})(exports.AmazonLocale || (exports.AmazonLocale = {}));

exports.AddCBIntegrationRequest = AddCBIntegrationRequest;
exports.AddConnectWiseIntegrationRequest = AddConnectWiseIntegrationRequest;
exports.AddDAndHIntegrationRequest = AddDAndHIntegrationRequest;
exports.AddEmailIntegrationRequest = AddEmailIntegrationRequest;
exports.AddHubspotIntegrationRequest = AddHubspotIntegrationRequest;
exports.AddIngramIntegrationRequest = AddIngramIntegrationRequest;
exports.AddQuickbooksIntegrationRequest = AddQuickbooksIntegrationRequest;
exports.AddRepairShoprIntegrationRequest = AddRepairShoprIntegrationRequest;
exports.AddSyncroIntegrationRequest = AddSyncroIntegrationRequest;
exports.AddSynnexIntegrationRequest = AddSynnexIntegrationRequest;
exports.AddTechDataIntegrationRequest = AddTechDataIntegrationRequest;
exports.AddVendorHubspotIntegrationRequest = AddVendorHubspotIntegrationRequest;
exports.AddWestcoastIntegrationRequest = AddWestcoastIntegrationRequest;
exports.AutoPriceUpdateDetails = AutoPriceUpdateDetails;
exports.AutotaskIntegration = AutotaskIntegration;
exports.CWProductFieldMapping = CWProductFieldMapping;
exports.ConnectBoosterIntegration = ConnectBoosterIntegration;
exports.ConnectCalendarPostRequest = ConnectCalendarPostRequest;
exports.ConnectWiseIntegration = ConnectWiseIntegration;
exports.ConnectionDetailsResponse = ConnectionDetailsResponse;
exports.ConnectwiseCredentialsTestRequest = ConnectwiseCredentialsTestRequest;
exports.ConnectwiseCustomFieldMapping = ConnectwiseCustomFieldMapping;
exports.ConnectwiseDefaultValues = ConnectwiseDefaultValues;
exports.ConnectwiseFieldMappingRequest = ConnectwiseFieldMappingRequest;
exports.ConnectwiseHost = ConnectwiseHost;
exports.ConnectwiseSettingsRequest = ConnectwiseSettingsRequest;
exports.CustomDistributorFTPConnectionRequest = CustomDistributorFTPConnectionRequest;
exports.CustomDistributorIntegration = CustomDistributorIntegration;
exports.CustomDistributorSecureApiConnectionRequest = CustomDistributorSecureApiConnectionRequest;
exports.CustomDistributorUpdateRequest = CustomDistributorUpdateRequest;
exports.CustomFieldCount = CustomFieldCount;
exports.CustomFieldCountPartnerAlign = CustomFieldCountPartnerAlign;
exports.DAndHIntegration = DAndHIntegration;
exports.DeleteConnectWiseIntegrationRequest = DeleteConnectWiseIntegrationRequest;
exports.DeleteZapierIntegrationRequest = DeleteZapierIntegrationRequest;
exports.DynamicPricingDistributors = DynamicPricingDistributors;
exports.EmailIntegration = EmailIntegration;
exports.EndUserLicenseAgreement = EndUserLicenseAgreement;
exports.FieldMappingRequest = FieldMappingRequest;
exports.FieldMappingResponse = FieldMappingResponse;
exports.GoogleDriveIntegration = GoogleDriveIntegration;
exports.HeadersField = HeadersField;
exports.HubspotClientPushSettings = HubspotClientPushSettings;
exports.HubspotCompanyPullFiltersRequest = HubspotCompanyPullFiltersRequest;
exports.HubspotContactPullFiltersRequest = HubspotContactPullFiltersRequest;
exports.HubspotDealPullFiltersRequest = HubspotDealPullFiltersRequest;
exports.HubspotDefaultPullEntities = HubspotDefaultPullEntities;
exports.HubspotIntegration = HubspotIntegration;
exports.HubspotOpportunityPipelineMappingRequest = HubspotOpportunityPipelineMappingRequest;
exports.HubspotOpportunityPushSettings = HubspotOpportunityPushSettings;
exports.HubspotPipelineStageMappingRequest = HubspotPipelineStageMappingRequest;
exports.HubspotPullSettingsRequest = HubspotPullSettingsRequest;
exports.HubspotPushSettingsRequest = HubspotPushSettingsRequest;
exports.HubspotStageDetails = HubspotStageDetails;
exports.ImportTaxRequest = ImportTaxRequest;
exports.InfusionSoftIntegration = InfusionSoftIntegration;
exports.InfusionSoftSettingsRequest = InfusionSoftSettingsRequest;
exports.IngramApiRegions = IngramApiRegions;
exports.IngramIntegration = IngramIntegration;
exports.IngramXmlRegions = IngramXmlRegions;
exports.IntegrationPageDetails = IntegrationPageDetails;
exports.Integrations = Integrations;
exports.IntegrationsResponse = IntegrationsResponse;
exports.KaseyaCredentialsTestRequest = KaseyaCredentialsTestRequest;
exports.KaseyaDefaultValues = KaseyaDefaultValues;
exports.KaseyaFieldMappingRequest = KaseyaFieldMappingRequest;
exports.KaseyaIntegration = KaseyaIntegration;
exports.KaseyaSettingsRequest = KaseyaSettingsRequest;
exports.KaseyaURLHost = KaseyaURLHost;
exports.MetaDataRequest = MetaDataRequest;
exports.OpportunitySyncInfo = OpportunitySyncInfo;
exports.OrderFormCustomFields = OrderFormCustomFields;
exports.PSAList = PSAList;
exports.PricingField = PricingField;
exports.ProductTypeToBillingPeriodMap = ProductTypeToBillingPeriodMap;
exports.QuickbooksConnectedUser = QuickbooksConnectedUser;
exports.QuickbooksIntegration = QuickbooksIntegration;
exports.RepairShoprCredentialsTestRequest = RepairShoprCredentialsTestRequest;
exports.RepairShoprFieldMappingRequest = RepairShoprFieldMappingRequest;
exports.RepairShoprIntegration = RepairShoprIntegration;
exports.RepairShoprSettingsRequest = RepairShoprSettingsRequest;
exports.RoundingDetails = RoundingDetails;
exports.StringifiedKeyValuePair = StringifiedKeyValuePair;
exports.StringifiedLabelValuePair = StringifiedLabelValuePair;
exports.StringifyRepairShoprProduct = StringifyRepairShoprProduct;
exports.StringifySyncroProduct = StringifySyncroProduct;
exports.StripeIntegration = StripeIntegration;
exports.SupplierCreateRequest = SupplierCreateRequest;
exports.SupplierUpdateRequest = SupplierUpdateRequest;
exports.SyncroCredentialsTestRequest = SyncroCredentialsTestRequest;
exports.SyncroFieldMappingRequest = SyncroFieldMappingRequest;
exports.SyncroIntegration = SyncroIntegration;
exports.SyncroSettingsRequest = SyncroSettingsRequest;
exports.SynnexIntegration = SynnexIntegration;
exports.TechDataCustomerNoRegions = TechDataCustomerNoRegions;
exports.TechDataIntegration = TechDataIntegration;
exports.UserCredentials = UserCredentials;
exports.VendorHubspotIntegration = VendorHubspotIntegration;
exports.VendorHubspotPullSettingsRequest = VendorHubspotPullSettingsRequest;
exports.ZMPaymentsIntegraton = ZMPaymentsIntegraton;
exports.ZapierIntegration = ZapierIntegration;
exports.ZmToATCustomFieldTypeMap = ZmToATCustomFieldTypeMap;
exports.ZmToCwCustomFieldTypeMap = ZmToCwCustomFieldTypeMap;
exports.ZomentumStageDetails = ZomentumStageDetails;
exports.etilizeProductContentSources = etilizeProductContentSources;
exports.generateCustomFieldMappingRequest = generateCustomFieldMappingRequest;
exports.generateProductTypeToQuickbooksAccountMappingRequest = generateProductTypeToQuickbooksAccountMappingRequest;
exports.generateZMPaymentModeToQuickbooksMappingRequest = generateZMPaymentModeToQuickbooksMappingRequest;
exports.getConnectionTypeServerKey = getConnectionTypeServerKey;
exports.hubspotSelectedCompanyIds = hubspotSelectedCompanyIds;
exports.hubspotSelectedLifecycleStagesIds = hubspotSelectedLifecycleStagesIds;
exports.icecatLocaleArray = icecatLocaleArray;
exports.months = months;
exports.prefillContent = prefillContent;
exports.timezones = timezones;
