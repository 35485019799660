'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var index = require('../../Vendor/EmailTemplate/index.js');

exports.EmailThreadMessageEditorType = void 0;
(function (EmailThreadMessageEditorType) {
  EmailThreadMessageEditorType["Compose"] = "email-thread-message-compose-editor";
  EmailThreadMessageEditorType["Reply"] = "email-thread-message-reply-editor";
  EmailThreadMessageEditorType["Automate"] = "email-thread-message-automation-editor";
})(exports.EmailThreadMessageEditorType || (exports.EmailThreadMessageEditorType = {}));
var EmailThreadLabel = /** @class */function () {
  function EmailThreadLabel() {
    this.display_name = "";
    this.id = "";
    this.name = "";
  }
  return EmailThreadLabel;
}();
exports.EntityType = void 0;
(function (EntityType) {
  EntityType["Document"] = "document_type";
  EntityType["Opportunity"] = "opportunity_type";
  EntityType["Client"] = "client_type";
  EntityType["Partner"] = "partner_type";
  EntityType["VendorOpportunity"] = "vendor_opportunity_type";
  EntityType["VendorClient"] = "vendor_client_type";
  EntityType["VendorTemplate"] = "vendor_template_type";
  EntityType["User"] = "user_type";
  EntityType["PurchaseOrder"] = "purchase_order_type";
})(exports.EntityType || (exports.EntityType = {}));
var FetchMergeTagsRequest = /** @class */function () {
  function FetchMergeTagsRequest(args) {
    var _this = this;
    this.toEntityQueryString = function () {
      var result = [];
      if (_this.opportunityId) {
        result.push({
          entity_type: exports.EntityType.Opportunity,
          entity_id: _this.opportunityId
        });
      }
      if (_this.documentId) {
        result.push({
          entity_type: exports.EntityType.Document,
          entity_id: _this.documentId
        });
      }
      if (_this.clientId) {
        result.push({
          entity_type: exports.EntityType.Client,
          entity_id: _this.clientId
        });
      }
      if (_this.purchaseOrderId) {
        result.push({
          entity_type: exports.EntityType.PurchaseOrder,
          entity_id: _this.purchaseOrderId
        });
      }
      return result;
    };
    this.toEntityQueryStringforVendor = function () {
      var result = [];
      if (_this.partnerId && _this.opportunityId) {
        result.push({
          entity_type: exports.EntityType.VendorOpportunity,
          entity_id: _this.opportunityId
        });
      } else if (_this.opportunityId) {
        result.push({
          entity_type: exports.EntityType.VendorOpportunity,
          entity_id: _this.opportunityId
        });
      } else if (_this.partnerId) {
        result.push({
          entity_type: exports.EntityType.Partner,
          entity_id: _this.partnerId
        });
      } else if (_this.clientId) {
        result.push({
          entity_type: exports.EntityType.VendorClient,
          entity_id: _this.clientId
        });
      }
      return result;
    };
    this.opportunityId = args.opportunityId;
    this.documentId = args.documentId;
    this.clientId = args.clientId;
    this.partnerId = args.partnerId;
    this.purchaseOrderId = args.purchaseOrderId;
  }
  return FetchMergeTagsRequest;
}();
var EmailThreadParticipant = /** @class */function () {
  function EmailThreadParticipant() {
    this.email = "";
    this.name = "";
  }
  return EmailThreadParticipant;
}();
var EmailThreadRecipients = /** @class */function () {
  function EmailThreadRecipients() {
    this.email = null;
    this.name = "";
    this.id = "";
  }
  return EmailThreadRecipients;
}();
var EmailThreadFile = /** @class */function () {
  function EmailThreadFile() {
    this.account_id = "";
    this.content_disposition = "";
    this.content_type = "";
    this.filename = "";
    this.id = "";
    this.size = 0;
    this.file_size = 0;
    this.file_name = "";
  }
  return EmailThreadFile;
}();
var EmailThreadMessage = /** @class */function () {
  function EmailThreadMessage() {
    this.account_id = "";
    this.bcc = new Array();
    this.body = "";
    this.body_updated = new Date();
    this.cc = new Array();
    this.date_time = new Date();
    this.draft_ids = new Array();
    this.email_id = "";
    this.email_ids_to_fetch = [];
    this.files = new Array();
    this.file_ids = new Array();
    this.from = new EmailThreadParticipant();
    this.has_attachments = false;
    this.id = "";
    this.labels = new Array();
    this.last_message_time_stamp = new Date();
    this.message_ids = new Array();
    this.object = "";
    this.participants = new Array();
    this.pinned = false;
    this.reply_to = new Array();
    this.snippet = "";
    this.starred = false;
    this.subject = "";
    this.to = new Array();
    this.unread = false;
    this.version = 0;
  }
  return EmailThreadMessage;
}();
var EmailThreadMessagesResponse = /** @class */function () {
  function EmailThreadMessagesResponse() {
    this.data = new Array();
  }
  return EmailThreadMessagesResponse;
}();
var DownloadEmailThreadMessageFileRequest = /** @class */function () {
  function DownloadEmailThreadMessageFileRequest(file, account_id) {
    this.file = file;
    this.account_id = account_id;
  }
  return DownloadEmailThreadMessageFileRequest;
}();
var SendEmailThreadMessageFormValues = /** @class */function () {
  function SendEmailThreadMessageFormValues() {
    this.account_id = "";
    this.body = "";
    this.subject = "";
    this.to = new Array();
    this.cc = new Array();
    this.bcc = new Array();
    this.file_ids = new Array();
    this.file_names = new Array();
    this.from = "";
    this.tracking = null;
    this.should_replace_with_empty_string = true;
    this.s3_file_ids = new Array();
    this.use_default_email = true;
    this.attach_document = false;
    this.attach_signing_log = false;
    this.attach_payment_log = false;
    this.attach_product_list = false;
  }
  return SendEmailThreadMessageFormValues;
}();
var SendEmailThreadMessageRequest = /** @class */function () {
  function SendEmailThreadMessageRequest() {
    this.account_id = "";
    this.body = "";
    this.signature = "";
    this.subject = "";
    this.to = new Array();
    this.cc = new Array();
    this.bcc = new Array();
    this.file_ids = new Array();
    this.document_id = "";
    this.from = new EmailThreadRecipients();
    this.should_replace_with_empty_string = true;
    this.mail_id = "";
    this.mail_type = exports.EntityType.Client;
    this.tracking = null;
    this.reply_to_message_id = "";
  }
  return SendEmailThreadMessageRequest;
}();
var EmailTracking = /** @class */function () {
  function EmailTracking() {
    this.links = false;
    this.opens = false;
    this.thread_replies = false;
  }
  return EmailTracking;
}();
exports.EmailRecipientsTypes = void 0;
(function (EmailRecipientsTypes) {
  EmailRecipientsTypes["TO"] = "to";
  EmailRecipientsTypes["CC"] = "cc";
  EmailRecipientsTypes["BCC"] = "bcc";
})(exports.EmailRecipientsTypes || (exports.EmailRecipientsTypes = {}));
var SendDocumentEmailThreadMessageRequest = /** @class */function () {
  function SendDocumentEmailThreadMessageRequest() {
    this.from = new EmailThreadRecipients();
    this.body = "";
    this.signature = "";
    this.subject = "";
    this.to = new Array();
    this.cc = new Array();
    this.bcc = new Array();
    this.document_id = "";
    this.should_replace_with_empty_string = true;
    this.file_ids = new Array();
  }
  return SendDocumentEmailThreadMessageRequest;
}();
var ValidateEmailMergeTagsRequest = /** @class */function () {
  function ValidateEmailMergeTagsRequest() {
    this.from = new EmailThreadRecipients();
    this.to = new Array();
    this.cc = new Array();
    this.bcc = new Array();
    this.body = "";
    this.subject = "";
    this.mail_id = "";
    this.mail_type = null;
  }
  return ValidateEmailMergeTagsRequest;
}();
var GetEmailPreviewRequest = /** @class */function () {
  function GetEmailPreviewRequest() {
    this.from = new EmailThreadRecipients();
    this.to = new Array();
    this.cc = new Array();
    this.bcc = new Array();
    this.body = "";
    this.subject = "";
    this.mail_id = "";
    this.mail_type = exports.EntityType.Client;
    this.should_replace_with_empty_string = true;
    this.account_id = "";
    this.file_ids = new Array();
    this.system_template_info = null;
    this.signature = "";
    this.template_type = index.VendorCustomTemplateType.PublishTemplate;
  }
  return GetEmailPreviewRequest;
}();
var GetDocumentEmailPreviewRequest = /** @class */function () {
  function GetDocumentEmailPreviewRequest() {
    this.to = new Array();
    this.cc = new Array();
    this.bcc = new Array();
    this.body = "";
    this.subject = "";
    this.should_replace_with_empty_string = true;
    this.documentId = "";
    this.signature = "";
  }
  return GetDocumentEmailPreviewRequest;
}();

exports.DownloadEmailThreadMessageFileRequest = DownloadEmailThreadMessageFileRequest;
exports.EmailThreadFile = EmailThreadFile;
exports.EmailThreadLabel = EmailThreadLabel;
exports.EmailThreadMessage = EmailThreadMessage;
exports.EmailThreadMessagesResponse = EmailThreadMessagesResponse;
exports.EmailThreadParticipant = EmailThreadParticipant;
exports.EmailThreadRecipients = EmailThreadRecipients;
exports.EmailTracking = EmailTracking;
exports.FetchMergeTagsRequest = FetchMergeTagsRequest;
exports.GetDocumentEmailPreviewRequest = GetDocumentEmailPreviewRequest;
exports.GetEmailPreviewRequest = GetEmailPreviewRequest;
exports.SendDocumentEmailThreadMessageRequest = SendDocumentEmailThreadMessageRequest;
exports.SendEmailThreadMessageFormValues = SendEmailThreadMessageFormValues;
exports.SendEmailThreadMessageRequest = SendEmailThreadMessageRequest;
exports.ValidateEmailMergeTagsRequest = ValidateEmailMergeTagsRequest;
