'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var _a;
exports.EIcons = void 0;
(function (EIcons) {
  EIcons["ADMINPROFILE"] = "admin-profile";
  EIcons["ADD"] = "add";
  EIcons["ADDTOCOLLECTION"] = "add-to-collection";
  EIcons["ADDPRODUCT"] = "add-product";
  EIcons["ADDCIRCLE"] = "add-circle";
  EIcons["AISTAR"] = "ai-star";
  EIcons["AICOMPANYBRIEF"] = "ai-company-brief";
  EIcons["AIAUTOCONFIG"] = "ai-auto-config";
  EIcons["ALIGNBOTTOM"] = "align-bottom";
  EIcons["ALIGNCENTER"] = "align-center";
  EIcons["ALIGNTOP"] = "align-top";
  EIcons["AMOUNT"] = "amount";
  EIcons["ANGULARBRACKET"] = "angular-bracket";
  EIcons["APPROVALRULES"] = "approval-rules";
  EIcons["ARROWDOWN"] = "arrow-down";
  EIcons["ARROWLEFT"] = "arrow-left";
  EIcons["ARROWLEFTCURVESHARP"] = "arrow-left-curve-sharp";
  EIcons["ARROWRIGHT"] = "arrow-right";
  EIcons["ARROWUP"] = "arrow-up";
  EIcons["ASSIGNED"] = "assigned";
  EIcons["ATEXT"] = "a-text";
  EIcons["ATEXTCOLOR"] = "a-text-color";
  EIcons["ATTACHMENT"] = "attachment";
  EIcons["AUDITLOGS"] = "audit-logs";
  EIcons["BLOCK"] = "block";
  EIcons["BOLDB"] = "bold-b";
  EIcons["BOLDITALICUNDERLINE"] = "bold-italic-underline";
  EIcons["BOLDT"] = "bold-t";
  EIcons["BOLDUNDERLINE"] = "bold-underline";
  EIcons["BOLD"] = "bold";
  EIcons["BOOKMARKREMOVE"] = "bookmark-remove";
  EIcons["BOOKMARKSLASH"] = "bookmark-slash";
  EIcons["BOOKMARKTAG"] = "bookmark-tag";
  EIcons["BOOKMARK"] = "bookmark";
  EIcons["BOOKMARKS"] = "bookmarks";
  EIcons["CALENDAR"] = "calendar";
  EIcons["CALENDARADD"] = "calendar-add";
  EIcons["CALENDARDELETE"] = "calendar-delete";
  EIcons["CALENDARREMOVE"] = "calendar-remove";
  EIcons["CALENDARSUCCESS"] = "calendar-success";
  EIcons["CALENDARWARNING"] = "calendar-warning";
  EIcons["CANCEL"] = "cancel";
  EIcons["CAPSLOCKKEY"] = "caps-lock-key";
  EIcons["CARD"] = "card";
  EIcons["CHARTPIE"] = "chart-pie";
  EIcons["CHART"] = "chart";
  EIcons["CHECK"] = "check";
  EIcons["CHECKCIRCLE"] = "check-circle";
  EIcons["CHEVRONDOUBLELEFT"] = "chevron-double-left";
  EIcons["CHEVRONDOUBLERIGHT"] = "chevron-double-right";
  EIcons["CHEVRONDOUBLEDOWN"] = "chevron-double-down";
  EIcons["CHEVRONDOUBLEUP"] = "chevron-double-up";
  EIcons["CHEVRONDOWN"] = "chevron-down";
  EIcons["CHEVRONDOWNV2"] = "chevron-down-v2";
  EIcons["CHEVRONLEFT"] = "chevron-left";
  EIcons["CHEVRONRIGHT"] = "chevron-right";
  EIcons["CHEVRONUP"] = "chevron-up";
  EIcons["CIRCLECHATDOT"] = "circle-chat-dot";
  EIcons["CIRCLENOTIFICATION"] = "circle-notification";
  EIcons["CIRCLETICK"] = "circle-tick";
  EIcons["CIRCLEQUESTION"] = "circle-question";
  EIcons["CIRCLETICKSOLID"] = "circle-tick-solid";
  EIcons["CLEARTEXTFORMAT"] = "clear-text-format";
  EIcons["CLONE"] = "clone";
  EIcons["CLOSE"] = "close";
  EIcons["CLOSECIRCLEFILLED"] = "close-circle-filled";
  EIcons["CLOSECIRCLE"] = "close-circle";
  EIcons["CLOUDASSESSMENT"] = "cloud-assessment";
  EIcons["COINSTACK"] = "coin-stack";
  EIcons["COLLECTPAYMENTS"] = "collect-payments";
  EIcons["COLLECTPAYMENTSV2"] = "collect-payments-v2";
  EIcons["COLUMN"] = "column";
  EIcons["COMPANY"] = "company";
  EIcons["COPY"] = "copy";
  EIcons["CUSTOMEFIELDS"] = "customfields";
  EIcons["DASHEDLIST"] = "dashed-list";
  EIcons["DATE"] = "date";
  EIcons["DECREASEINDENT"] = "decrease-indent";
  EIcons["DISPLAY"] = "display";
  EIcons["DIVIDE"] = "divide";
  EIcons["DOCASSESSMENT"] = "doc-assessment";
  EIcons["DOCCONTRACT"] = "doc-contract";
  EIcons["DOCPROPOSAL"] = "doc-proposal";
  EIcons["DOCQBR"] = "doc-qbr";
  EIcons["DOCQUOTE"] = "doc-quote";
  EIcons["DOCORDERFORM"] = "doc-order-form";
  EIcons["DOCUMENTAI"] = "document-ai";
  EIcons["DOLLARSIGN"] = "dollar-sign";
  EIcons["DOWNSQUARE"] = "down-square";
  EIcons["DOWNLOADCLOUD"] = "download-cloud";
  EIcons["DOWNLOAD"] = "download";
  EIcons["DRAG"] = "drag";
  EIcons["DRAGBLOCK"] = "drag-block";
  EIcons["EMAILSETTING"] = "email-setting";
  EIcons["EMAILTEMPLATE"] = "email-template";
  EIcons["ENLIGHT"] = "enlight";
  EIcons["EXTERNALLINK"] = "external-link";
  EIcons["EYE"] = "eye";
  EIcons["EYEOFF"] = "eye-off";
  EIcons["FACEBOOK"] = "facebook";
  EIcons["FACEBOOK2"] = "facebook-2";
  EIcons["FACEPLUS"] = "faceplus";
  EIcons["FILE"] = "file";
  EIcons["FILEADD"] = "file-add";
  EIcons["FILEDONE"] = "file-done";
  EIcons["FILESEARCH"] = "file-search";
  EIcons["FILTER"] = "filter";
  EIcons["FILTERCIRCLESOLID"] = "filter-circle-solid";
  EIcons["FLIPVERTICAL"] = "flip-vertical";
  EIcons["FLIPHORIZONTAL"] = "flip-horizontal";
  EIcons["FOCUS"] = "focus";
  EIcons["FOLDER"] = "folder";
  EIcons["FONTSIZE"] = "font-size";
  EIcons["GIFT"] = "gift";
  EIcons["GLOBAL"] = "global";
  EIcons["GRAPH"] = "graph";
  EIcons["GRIDVIEW"] = "grid-view";
  EIcons["HASHTAG"] = "hashtag";
  EIcons["HELP"] = "help";
  EIcons["HORIZONTALCENTER"] = "horizontal-center";
  EIcons["HORIZONTALLEFT"] = "horizontal-left";
  EIcons["HORIZONTALRIGHT"] = "horizontal-right";
  EIcons["HISTORY"] = "history";
  EIcons["HIDETAX"] = "hide-tax";
  EIcons["HOME"] = "home";
  EIcons["IMAGEADD"] = "image-add";
  EIcons["IMAGE"] = "image";
  EIcons["IMAGEREMOVE"] = "image-remove";
  EIcons["IMAGETICK"] = "image-tick";
  EIcons["INCREASEINDENT"] = "increase-indent";
  EIcons["INFOCIRCLE"] = "info-circle";
  EIcons["INPUTFIELD"] = "input-field";
  EIcons["INTEGRATIONS"] = "integrations";
  EIcons["ITALICT"] = "italic-t";
  EIcons["ITALIC"] = "italic";
  EIcons["JUSTIFIED"] = "justified";
  EIcons["LINK"] = "link";
  EIcons["LIST"] = "list";
  EIcons["LOADING"] = "loading";
  EIcons["LOGOUT"] = "logout";
  EIcons["MAIL"] = "mail";
  EIcons["MAILNOTIFICATION"] = "mail-notification";
  EIcons["MAILSETTINGS"] = "mail-settings";
  EIcons["MAILSUCCESS"] = "mail-success";
  EIcons["MARKETPLACE"] = "marketplace";
  EIcons["MAXIMIZE"] = "maximize";
  EIcons["MEH"] = "meh-emoticon";
  EIcons["MENUSQUARESSPACED"] = "menu-squares-spaced";
  EIcons["MENUUNEQUAL"] = "menu-unequal";
  EIcons["MENUDESCENDING"] = "menu-descending";
  EIcons["MENUASCENDING"] = "menu-ascending";
  EIcons["MENUSQUARES"] = "menu-squares";
  EIcons["MENU"] = "menu";
  EIcons["MISSIONCONTROL"] = "mission-control";
  EIcons["MONEYBAG"] = "money-bag";
  EIcons["MONEYBAG2"] = "money-bag2";
  EIcons["MOREVERTICAL"] = "more-vertical";
  EIcons["MOVIE"] = "movie";
  EIcons["NETWORK"] = "network";
  EIcons["NEWFOLDER"] = "new-folder";
  EIcons["NEWIMAGE"] = "new-image";
  EIcons["NOTIFICATION"] = "notification";
  EIcons["NUMBEREDLIST"] = "numbered-list";
  EIcons["OPPORTUNITIES"] = "opportunities";
  EIcons["PACKAGE"] = "package";
  EIcons["PAGEBREAK"] = "page-break";
  EIcons["PARTNERS"] = "partners";
  EIcons["PAUSE"] = "pause";
  EIcons["PEN"] = "pen";
  EIcons["PENV2"] = "penv2";
  EIcons["PERCENT"] = "percent";
  EIcons["PHONE"] = "phone";
  EIcons["PIN"] = "pin";
  EIcons["PIPELINES"] = "pipelines";
  EIcons["PLAY"] = "play";
  EIcons["PORTRAIT"] = "portrait";
  EIcons["PRESENTATIONONBOARD"] = "presentation-on-board";
  EIcons["PRESENTATIONLINEGRAPH"] = "presentation-line-graph";
  EIcons["PRESENTATION"] = "presentation";
  EIcons["PRESENTATIONSCREEN"] = "presentation-screen";
  EIcons["PRINT"] = "print";
  EIcons["PRODUCTS"] = "products";
  EIcons["PURCHASEORDER"] = "purchase-order";
  EIcons["RECEIPT"] = "receipt";
  EIcons["REFRESH"] = "refresh";
  EIcons["REMOVE"] = "remove";
  EIcons["REMOVEPOINT"] = "remove-point";
  EIcons["REMOVEFROMCOLLECTION"] = "remove-from-collection";
  EIcons["REPLYEMAIL"] = "reply-email";
  EIcons["RESEARCH"] = "research";
  EIcons["RESEND"] = "resend";
  EIcons["RESOURCES"] = "resources";
  EIcons["ROCKET"] = "rocket";
  EIcons["ROW"] = "row";
  EIcons["SAVE"] = "save";
  EIcons["SALESACTIVITY"] = "sales-activity";
  EIcons["SCAN"] = "scan";
  EIcons["SEARCH"] = "search";
  EIcons["SENDBUTTONROUNDSLASH"] = "send-button-round-slash";
  EIcons["SENDBUTTONROUND"] = "send-button-round";
  EIcons["SENDBUTTONPLANE"] = "send-button-plane";
  EIcons["SENDBUTTONHORIZONTAL"] = "send-button-horizontal";
  EIcons["SENDBUTTONSLASH"] = "send-button-slash";
  EIcons["SENDBUTTON"] = "send-button";
  EIcons["SETPOINT"] = "set-point";
  EIcons["SETTING"] = "setting";
  EIcons["SHARE"] = "share";
  EIcons["SHIFTKEY"] = "shift-key";
  EIcons["SLIDERHORIZONTAL"] = "slider-horizontal";
  EIcons["SLIDERVERTICAL"] = "slider-vertical";
  EIcons["SOFTWARE"] = "software";
  EIcons["SORTDOWN"] = "sort-down";
  EIcons["SORTUP"] = "sort-up";
  EIcons["SPARKLES"] = "sparkles";
  EIcons["SQUARECHATDOT"] = "square-chat-dot";
  EIcons["STAR"] = "star";
  EIcons["STOP"] = "stop";
  EIcons["STOPWATCH"] = "stopwatch";
  EIcons["STRIKETHROUGHS"] = "strike-through-s";
  EIcons["STRIKETHROUGHT"] = "strike-through-t";
  EIcons["SHUFFLE"] = "shuffle";
  EIcons["SYNC"] = "sync";
  EIcons["TABLESMALLGRIDS"] = "table-small-grids";
  EIcons["TABLE"] = "table";
  EIcons["TAG"] = "tag";
  EIcons["TAXSETUP"] = "taxsetup";
  EIcons["TEXTBOX"] = "text-box";
  EIcons["TEXTCURSOR"] = "text-cursor";
  EIcons["TEXTCURSORT"] = "text-cursor-t";
  EIcons["TEXTHEIGHT"] = "text-height";
  EIcons["TEXTSIZEDECREASE"] = "text-size-decrease";
  EIcons["TEXTSIZEINCREASE"] = "text-size-increase";
  EIcons["TEXTSUBSCRIPT"] = "text-subscript";
  EIcons["TEXTSUPERSCRIPT"] = "text-superscript";
  EIcons["TEXTWEIGHT"] = "text-weight";
  EIcons["TEXT"] = "text";
  EIcons["TEXTCOLOR"] = "text-color";
  EIcons["TRASH"] = "trash";
  EIcons["TRASHV2"] = "trashv2";
  EIcons["TRIGGER"] = "trigger";
  EIcons["TWITTER"] = "twitter";
  EIcons["TWITTER2"] = "twitter-2";
  EIcons["UNDERLINEU"] = "underline-u";
  EIcons["UNDERLINET"] = "underline-t";
  EIcons["UPSQUARE"] = "up-square";
  EIcons["UPLOAD"] = "upload";
  EIcons["UPLOADV2"] = "upload-v2";
  EIcons["UPLOADCLOUD"] = "upload-cloud";
  EIcons["UPLOADCLOUDV2"] = "upload-cloud-v2";
  EIcons["USER"] = "user";
  EIcons["USERADDCIRCLE"] = "user-add-circle";
  EIcons["USERCIRCLE"] = "user-circle";
  EIcons["USERREMOVECIRCLE"] = "user-remove-circle";
  EIcons["USERS"] = "users";
  EIcons["USERSREVERSED"] = "users-reversed";
  EIcons["USERSGROUP"] = "users-group";
  EIcons["USERLAPTOP"] = "user-laptop";
  EIcons["USERVERIFIEDCIRCLE"] = "user-verified-circle";
  EIcons["VIDEOADD"] = "video-add";
  EIcons["WARNINGCIRCLE"] = "warning-circle";
  EIcons["WARNING"] = "warning";
  EIcons["WARNINGFILL"] = "warning-fill";
  EIcons["WEBSITE"] = "website";
  EIcons["WEBSITE2"] = "website-2";
  EIcons["WINDOWPOINTER"] = "window-pointer";
  EIcons["WINDOWPRESENTATION"] = "window-presentation";
  EIcons["NEWRESEND"] = "new-resend";
  EIcons["ZOMENTUM"] = "zomentum";
  EIcons["RELOAD"] = "reload";
  EIcons["MARKETPLACEINVOICES"] = "marketplace-invoices";
  EIcons["MARKETPLACECOMMISSIONS"] = "marketplace-commissions";
  EIcons["SUPPLIER"] = "supplier";
  EIcons["VENDORHOME"] = "vendor-home";
  EIcons["VENDORTEMPLATES"] = "vendor-templates";
  EIcons["VENDOROPPORTUNITIES"] = "vendor-opportunities";
  EIcons["VENDORRESOURCES"] = "vendor-resources";
  EIcons["VENDORPARTNERS"] = "vendor-partners";
  EIcons["VENDORSETTINGS"] = "vendor-settings";
  EIcons["VENDORCLIENTS"] = "vendor-clients";
  EIcons["ELLIPSIS"] = "ellipsis";
  EIcons["VENDORPRODUCTS"] = "vendor-products";
  EIcons["VENDORTIERS"] = "vendor-tiers";
  EIcons["INFOCIRCLEFILLED"] = "fill-info-circle";
  EIcons["VIDEOLOADING"] = "video-loading";
  EIcons["AUTHENTICATIONSUCCESS"] = "authentication-success";
  EIcons["AUTHENTICATIONFAILURE"] = "authentication-failure";
  EIcons["GOOGLEMEET"] = "google-meet";
  EIcons["MICROSOFTTEAMS"] = "microsoft-teams";
  EIcons["ZOOM"] = "zoom";
  EIcons["AIWRITER"] = "ai-writier";
  EIcons["REPLACE"] = "replace";
  EIcons["PAGENUMBER"] = "page-number";
  EIcons["PAPERPLANE"] = "paper-plane";
  EIcons["MERGETAG"] = "merge-tag";
  EIcons["REDIRECT"] = "redirect";
  EIcons["RESOURCEPRESENTATION"] = "resource-presentation";
  EIcons["RESOURCEPDF"] = "resource-pdf";
  EIcons["RESOURCETXT"] = "resource-txt";
  EIcons["RESOURCESPREADSHEET"] = "resource-spreadsheet";
  EIcons["RESOURCEDOCUMENT"] = "resource-document";
  EIcons["RESOURCEVIDEO"] = "resource-video";
  EIcons["RESOURCECSV"] = "resource-csv";
  EIcons["RESOURCEIMAGE"] = "resource-image";
  EIcons["FINANCE"] = "finance";
  EIcons["TROPHY"] = "trophy";
  EIcons["REVENUE"] = "revenue";
  EIcons["TIMER"] = "timer";
  EIcons["AIWRITERWHITE"] = "ai-writier-white";
  EIcons["CANCELWITHBACKGROUND"] = "cancel-with-background";
  EIcons["AIWRITERLOADING"] = "ai-writer-loading";
  EIcons["CLOCK"] = "clock";
  EIcons["BARCHART"] = "barchart";
})(exports.EIcons || (exports.EIcons = {}));
exports.ETwoToneIcons = void 0;
(function (ETwoToneIcons) {
  ETwoToneIcons["ACTIVESTEP"] = "active-step";
  ETwoToneIcons["ADDONS"] = "add-ons";
  ETwoToneIcons["ADMIN"] = "admin";
  ETwoToneIcons["APIUSER"] = "api-user";
  ETwoToneIcons["ASSIGNEDUSER"] = "assigned-user";
  ETwoToneIcons["BILLING"] = "billing";
  ETwoToneIcons["CALENDAR"] = "calendar";
  ETwoToneIcons["CALENDAR_ORANGE"] = "calendar-orange";
  ETwoToneIcons["CLIENT"] = "clients";
  ETwoToneIcons["COMPLETEDSTEP"] = "completed-step";
  ETwoToneIcons["CONTACT"] = "contact";
  ETwoToneIcons["DISABLEDSTEP"] = "disabled-step";
  ETwoToneIcons["DIVIDE"] = "divide";
  ETwoToneIcons["DOCUMENT"] = "document";
  ETwoToneIcons["DOCUMENTEMAILSETUP"] = "document-email-setup";
  ETwoToneIcons["DOLLAR"] = "dollar";
  ETwoToneIcons["DOWNLOAD"] = "download";
  ETwoToneIcons["EMAILANDCALENDAR"] = "email-and-calendar";
  ETwoToneIcons["EYETWOTONE"] = "eye-two-tone";
  ETwoToneIcons["FOLDERTWOTONE"] = "folder-two-tone";
  ETwoToneIcons["GLOBE"] = "globe";
  ETwoToneIcons["HIGH"] = "high";
  ETwoToneIcons["IMAGE"] = "image";
  ETwoToneIcons["INVISIBLEEYE"] = "invisible-eye";
  ETwoToneIcons["LOCATION"] = "location";
  ETwoToneIcons["LOW"] = "low";
  ETwoToneIcons["MAIL"] = "mail";
  ETwoToneIcons["MAIL_ORANGE"] = "mail-orange";
  ETwoToneIcons["MAILADD"] = "mail-add";
  ETwoToneIcons["MAILNOTIFICATION"] = "mail-notification";
  ETwoToneIcons["MAILSUCCESS"] = "mail-success";
  ETwoToneIcons["MAILTRACKING"] = "mail-tracking";
  ETwoToneIcons["MAILWARNING"] = "mail-warning";
  ETwoToneIcons["MEDIUM"] = "medium";
  ETwoToneIcons["NOTES"] = "notes";
  ETwoToneIcons["NOTES_ORANGE"] = "notes-orange";
  ETwoToneIcons["MANAGER"] = "manager";
  ETwoToneIcons["NOTIFICATION"] = "notification";
  ETwoToneIcons["OPPORTUNITY"] = "opportunity";
  ETwoToneIcons["PACKAGE"] = "package";
  ETwoToneIcons["PARTNERS"] = "partners";
  ETwoToneIcons["PASSWORD"] = "password";
  ETwoToneIcons["PHONE"] = "phone";
  ETwoToneIcons["PHONE_ORANGE"] = "phone-orange";
  ETwoToneIcons["PRIVACY"] = "privacy";
  ETwoToneIcons["SPREADSHEET"] = "spreadsheet";
  ETwoToneIcons["SOFTWARE"] = "software";
  ETwoToneIcons["TASK"] = "task";
  ETwoToneIcons["TASK_ORANGE"] = "task-orange";
  ETwoToneIcons["USER"] = "user";
  ETwoToneIcons["WARNING"] = "warning";
  ETwoToneIcons["GRIDVIEW"] = "grid-view";
  ETwoToneIcons["VIDEOCAMERA"] = "video-camera";
  ETwoToneIcons["SLIDERHORIZONTALACTIVE"] = "slider-horizontal-active";
  ETwoToneIcons["TRANSLATE"] = "translate";
  ETwoToneIcons["RESOURCEPDFTHUMBNAIL"] = "resource-pdf-thumbnail";
  ETwoToneIcons["RESOURCESPREADSHEETTHUMBNAIL"] = "resource-spreadsheet-thumbnail";
  ETwoToneIcons["RESOURCEPRESENTATIONTHUMBNAIL"] = "resource-presentation-thumbnail";
  ETwoToneIcons["RESOURCETXTTHUMBNAIL"] = "resource-txt-thumbnail";
  ETwoToneIcons["RESOURCEDOCTHUMBNAIL"] = "resource-doc-thumbnail";
  ETwoToneIcons["RESOURCEVIDEOTHUMBNAIL"] = "resource-video-thumbnail";
  ETwoToneIcons["RESOURCEIMAGETHUMBNAIL"] = "resource-image-thumbnail";
  ETwoToneIcons["RESOURCECSVTHUMBNAIL"] = "resource-csv-thumbnail";
  ETwoToneIcons["SUCCESSCIRCLE"] = "success-circle";
  ETwoToneIcons["COMPLETEDSTEPOUTLINE"] = "completed-step-outline";
  ETwoToneIcons["GDOCQUOTEICONBLUE"] = "gdoc-quote-icon-blue";
  ETwoToneIcons["GDOCQUOTEICONGRAY"] = "gdoc-quote-icon-gray";
  ETwoToneIcons["GDOCMERGETAGICONBLUE"] = "gdoc-merge-tag-icon-blue";
  ETwoToneIcons["GDOCMERGETAGICONGRAY"] = "gdoc-merge-tag-icon-gray";
  ETwoToneIcons["GDOCINPUTFIELDICONBLUE"] = "gdoc-input-field-icon-blue";
  ETwoToneIcons["GDOCINPUTFIELDICONGRAY"] = "gdoc-input-field-icon-gray";
  ETwoToneIcons["GDOCSIGNATUREICONBLUE"] = "gdoc-signature-icon-blue";
  ETwoToneIcons["GDOCSIGNATUREICONGRAY"] = "gdoc-signature-icon-gray";
})(exports.ETwoToneIcons || (exports.ETwoToneIcons = {}));
exports.EIllustrations = void 0;
(function (EIllustrations) {
  EIllustrations["MICROSOFT"] = "microsoft";
  EIllustrations["AVATAR"] = "avatar";
  EIllustrations["THANKYOUFORMCHECKMARK"] = "thank-you-form-checkmark";
  EIllustrations["EMPTY_NOTIFICATIONS"] = "empty-notifications";
  EIllustrations["MARKETPLACE_ILLUSTRATION"] = "marketplace-illustration";
  EIllustrations["MARKETPLACE_PLACEHOLDER_ICON"] = "marketplace-placeholder-icon";
  EIllustrations["GDPR"] = "gdpr";
  EIllustrations["NO_RESULT_FOUND"] = "no-result-found";
  EIllustrations["HIPAA"] = "hipaa";
  EIllustrations["PCI"] = "pci";
  EIllustrations["ZOMENTUM_GRAY"] = "zomentum-gray";
  EIllustrations["GDOC_ADD_FIRST_QUOTE"] = "gdoc-add-first-quote";
  EIllustrations["GDOC_ADD_FIRST_SIGNATURE"] = "gdoc-add-first-signature";
})(exports.EIllustrations || (exports.EIllustrations = {}));
var IllustrationDimensions = (_a = {}, _a[exports.EIllustrations.MICROSOFT] = {
  width: 175,
  height: 84
}, _a[exports.EIllustrations.AVATAR] = {
  width: 32,
  height: 32
}, _a[exports.EIllustrations.THANKYOUFORMCHECKMARK] = {
  width: 281,
  height: 161
}, _a[exports.EIllustrations.EMPTY_NOTIFICATIONS] = {
  width: 157,
  height: 109
}, _a[exports.EIllustrations.MARKETPLACE_ILLUSTRATION] = {
  width: 396,
  height: 120
}, _a[exports.EIllustrations.GDPR] = {
  width: 65,
  height: 20
}, _a[exports.EIllustrations.HIPAA] = {
  width: 65,
  height: 20
}, _a[exports.EIllustrations.PCI] = {
  width: 65,
  height: 20
}, _a[exports.EIllustrations.MARKETPLACE_PLACEHOLDER_ICON] = {
  width: 40,
  height: 40
}, _a[exports.EIllustrations.NO_RESULT_FOUND] = {
  width: 184,
  height: 117
}, _a[exports.EIllustrations.ZOMENTUM_GRAY] = {
  width: 80,
  height: 80
}, _a[exports.EIllustrations.GDOC_ADD_FIRST_QUOTE] = {
  width: 77,
  height: 90
}, _a[exports.EIllustrations.GDOC_ADD_FIRST_SIGNATURE] = {
  width: 82,
  height: 90
}, _a);
exports.EVectorIcons = void 0;
(function (EVectorIcons) {
  EVectorIcons["PINHUMAN"] = "pin-human";
})(exports.EVectorIcons || (exports.EVectorIcons = {}));
exports.ESizeMaps = void 0;
(function (ESizeMaps) {
  ESizeMaps[ESizeMaps["xs"] = 12] = "xs";
  ESizeMaps[ESizeMaps["sm"] = 16] = "sm";
  ESizeMaps[ESizeMaps["md"] = 24] = "md";
  ESizeMaps[ESizeMaps["lg"] = 32] = "lg";
  ESizeMaps[ESizeMaps["xl"] = 48] = "xl";
  ESizeMaps[ESizeMaps["xxl"] = 64] = "xxl";
})(exports.ESizeMaps || (exports.ESizeMaps = {}));

exports.IllustrationDimensions = IllustrationDimensions;
