'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var Block = require('@zomentum/contracts/dist/ProposalBuilder/Block');
var contracts = require('@zomentum/contracts');
var Documents = require('@zomentum/contracts/dist/Documents');

/**
 *
 * @param documentId Document id whose session we you want to fetch
 *
 * @description Fetches the session based on the document when the document is pin protected
 */
var isGivenDocumentOrderForm = function (currentActiveDocument) {
  var document_type = (currentActiveDocument || {}).document_type;
  return document_type === Documents.DocumentType.GENERIC_ORDER_FORM || document_type === Documents.DocumentType.SPECIFIC_ORDER_FORM;
};
var isAssessmentOrQbrBlockUtil = function (blockType) {
  if (blockType === contracts.BlockType.RISK_LISTING || blockType === contracts.BlockType.HEALTH_STATUS || blockType === contracts.BlockType.HEALTH_LEGEND || blockType === contracts.BlockType.RISK_BAR || blockType === contracts.BlockType.RISK_MATRIX) {
    return true;
  }
  return false;
};
// issue is (295.765).toFixed(2) give the result as "295.76", but it should be "295.77"
// so using this method to round the number to 2 decimal places
function roundNum(num, length) {
  return Math.round(num * Math.pow(10, length)) / Math.pow(10, length);
}
// This method gets payable amount of document, which doesn't include adjustment fees
var getPayableAmount = function (payment, grand) {
  if ((payment === null || payment === void 0 ? void 0 : payment.custom_amount) != null || (payment === null || payment === void 0 ? void 0 : payment.custom_amount) != undefined) return payment === null || payment === void 0 ? void 0 : payment.custom_amount;
  if ((payment === null || payment === void 0 ? void 0 : payment.custom_percentage) != null || (payment === null || payment === void 0 ? void 0 : payment.custom_percentage) != undefined) return roundNum((grand !== null && grand !== void 0 ? grand : 0) * (payment === null || payment === void 0 ? void 0 : payment.custom_percentage) / 100, 2);
  return grand;
};
var getAdjustmentFeesCharged = function (payment) {
  var _a, _b, _c, _d;
  if (!payment) return undefined;
  if (payment.status && !(payment.status === Documents.PaymentStatus.Paid || payment.status === Documents.PaymentStatus.Opt_Out)) return undefined;
  switch (payment.payment_method) {
    case Documents.PaymentMethod.CREDIT_CARD:
      return (_b = (_a = payment.payment_options) === null || _a === void 0 ? void 0 : _a.credit_card) === null || _b === void 0 ? void 0 : _b.total_adjustment_fees;
    case Documents.PaymentMethod.ACH:
      return (_d = (_c = payment.payment_options) === null || _c === void 0 ? void 0 : _c.ach) === null || _d === void 0 ? void 0 : _d.total_adjustment_fees;
    default:
      return undefined;
  }
};
var convertHtmlElementToInputField = function (element, signers) {
  var _a, _b;
  if (signers === void 0) {
    signers = [];
  }
  if (element === null || !element.dataset.fieldType) {
    return null;
  }
  var assignee = (_a = signers === null || signers === void 0 ? void 0 : signers.find(function (signer) {
    return signer.id === element.dataset.fieldAssignee;
  })) === null || _a === void 0 ? void 0 : _a.id;
  var inputField = new Documents.InputField();
  inputField.type = element.dataset.fieldType;
  inputField.assignee = assignee || element.dataset.fieldAssignee || "";
  if (!inputField.assignee || inputField.assignee === "undefined") {
    // checking with undefined as string because from form the value is taken as undefined and stored as string
    inputField.assignee = undefined;
  }
  inputField.label = element.dataset.fieldLabel || "";
  inputField.placeholder = element.dataset.fieldPlaceholder || "";
  inputField.length = +(element.dataset.fieldLength || "10");
  inputField.isRequired = element.dataset.fieldIsrequired === "true";
  inputField.dataRole = element.dataset.fieldRole;
  inputField.external_id = (_b = element.dataset.fieldExternalid) !== null && _b !== void 0 ? _b : "";
  return inputField;
};
var getTag = function (type) {
  if (type === Documents.InputFieldType.Text) {
    return "t";
  } else if (type === Documents.InputFieldType.Date) {
    return "d";
  } else {
    return "c";
  }
};
var constructInputTag = function (_a) {
  var inputField = _a.inputField,
    forPdf = _a.forPdf,
    signers = _a.signers,
    parentTextBlockBackgroundColor = _a.parentTextBlockBackgroundColor,
    setPlaceholderForPdf = _a.setPlaceholderForPdf,
    id = _a.id;
  var placeholder = inputField.placeholder || "";
  var emptySpaceLength = inputField.length - placeholder.length;
  var emptySpace = emptySpaceLength > 0 ? "".concat(new Array(emptySpaceLength).fill(".").join("")) : "";
  var emptySpaceSpan = "<span style=\"opacity: 0;\">".concat(emptySpace, "</span>");
  var tag = getTag(inputField.type);
  var index = signers.map(function (a) {
    return a.id;
  }).indexOf(inputField.assignee || "");
  var isRequired = inputField.isRequired;
  var tags = !!id ? "[[#Z".concat(id) : "[[".concat(tag, "|").concat(index, "|p:0|r:").concat(isRequired ? 1 : 0, "|l:").concat(inputField.length).concat(inputField.external_id ? "|id:".concat(inputField.external_id) : "");
  var excessLength = emptySpaceLength + placeholder.length - tags.length;
  var excessSpace = excessLength > 0 ? new Array(excessLength).fill("#").join("") : "";
  var placeholderSpace = emptySpaceLength - tags.length > 0 ? new Array(emptySpaceLength - tags.length).fill("#").join("") : "";
  var alphaRemovedColor = contracts.removeAlphaFromRgba(parentTextBlockBackgroundColor);
  var excessSpaceForInputFields = 3;
  var spaceNeeded = inputField.length > placeholder.length ? inputField.length : placeholder.length;
  var inputFieldIdBasedSpaceHolder = spaceNeeded < tags.length ? "#".repeat(tags.length - spaceNeeded < excessSpaceForInputFields ? excessSpaceForInputFields : 0) : "#".repeat(spaceNeeded - tags.length + excessSpaceForInputFields);
  var modifierTag = "<span style=\"letter-spacing: -3px; color: rgba(0, 0, 0, 0);\">".concat(tags).concat(excessSpace, "]]</span>");
  var str = "<span class=\"input-field-placeholder\" style=\"padding: 0 4px 0 4px;\">".concat(placeholder.length > 5 || tag !== "t" ? placeholder : "<span style=\"position: absolute; left:4px; background: #ffedb4;\">".concat(placeholder, "</span>").concat(modifierTag), "</span>").concat(emptySpaceSpan);
  if (forPdf) {
    return "".concat(inputField.label ? inputField.label + " " : "", " <span class=\"input-field-print\" style=\"white-space: pre; display: inline-block; color: ").concat(alphaRemovedColor, "; opacity:0.01; ").concat(inputField.type === Documents.InputFieldType.CheckBox && "width: 1.4rem;", "\">").concat(tags).concat(!id ? "".concat(setPlaceholderForPdf ? "|n:".concat(placeholder).concat(placeholderSpace) : excessSpace) : inputFieldIdBasedSpaceHolder, "]]</span>");
  } else {
    return "".concat(inputField.label ? inputField.label + " " : "", "<span style=\"white-space: pre; background: #ffedb4; display: inline-block; white-space: pre; color: #7f7f7f; ").concat(inputField.type === Documents.InputFieldType.CheckBox ? "width: 1.4rem;" : "", "\">").concat(str, "</span>");
  }
};
var setGOFBlockData = function (document, block) {
  var _a, _b;
  var documentId = document.id,
    updated = document.updated;
  var existingData = contracts.cache.get(contracts.ZomentumStorageKeys.GOF_BLOCK_CACHE, true);
  var blockList = {};
  if (!existingData) {
    existingData = {};
  }
  if (existingData[documentId]) {
    blockList = (_a = existingData[documentId].blocks) !== null && _a !== void 0 ? _a : {};
  } else {
    existingData[documentId] = {
      blocks: blockList
    };
  }
  var blockData = block === null || block === void 0 ? void 0 : block.data;
  var sectionsData = (_b = blockData === null || blockData === void 0 ? void 0 : blockData.sections) === null || _b === void 0 ? void 0 : _b.map(function (section) {
    var selected_products = section.selected_products,
      products = section.products;
    var newProductsList = products.map(function (product) {
      var quantity = product.quantity,
        bundled_products = product.bundled_products;
      var newBundledProducts = bundled_products === null || bundled_products === void 0 ? void 0 : bundled_products.map(function (_a) {
        var quantity = _a.quantity;
        return new Block.GOFBundledProductChanges(quantity);
      });
      return new Block.GOFProductChanges(quantity, newBundledProducts || []);
    });
    return new Block.GOFBlockSection(selected_products, newProductsList || []);
  });
  var newChangedBlock = new Block.GOFBlockChanges(block.id, new Block.GOFBlockDataChanges(sectionsData, blockData === null || blockData === void 0 ? void 0 : blockData.tax, blockData === null || blockData === void 0 ? void 0 : blockData.total), block.updated);
  blockList[block.id] = newChangedBlock;
  existingData[documentId].blocks = blockList;
  existingData[documentId].updated = updated;
  contracts.cache.save(contracts.ZomentumStorageKeys.GOF_BLOCK_CACHE, existingData);
};
var setGOFPaymentData = function (documentId, payment) {
  var existingData = contracts.cache.get(contracts.ZomentumStorageKeys.GOF_BLOCK_CACHE, true);
  var documentData = existingData === null || existingData === void 0 ? void 0 : existingData[documentId];
  if (documentData) {
    documentData.payment = payment;
    contracts.cache.save(contracts.ZomentumStorageKeys.GOF_BLOCK_CACHE, existingData);
  }
};
var getGOFDocumentData = function (documentId) {
  var _a, _b;
  var existingData = contracts.cache.get(contracts.ZomentumStorageKeys.GOF_BLOCK_CACHE, true);
  var blockList = (_a = existingData === null || existingData === void 0 ? void 0 : existingData[documentId]) === null || _a === void 0 ? void 0 : _a.blocks;
  if (blockList) {
    existingData[documentId].blocks = (_b = Object.values(blockList)) === null || _b === void 0 ? void 0 : _b.map(function (block) {
      return {
        id: block.id,
        data: block.data
      };
    });
    return existingData[documentId];
  }
  return null;
};
var deleteGOFBlockData = function (documentId) {
  var existingData = contracts.cache.get(contracts.ZomentumStorageKeys.GOF_BLOCK_CACHE, true);
  existingData === null || existingData === void 0 ? true : delete existingData[documentId];
  contracts.cache.save(contracts.ZomentumStorageKeys.GOF_BLOCK_CACHE, existingData);
};
var updateGOFDocumentData = function (document) {
  var _a, _b, _c;
  var existingData = contracts.cache.get(contracts.ZomentumStorageKeys.GOF_BLOCK_CACHE, true);
  var documentData = existingData === null || existingData === void 0 ? void 0 : existingData[document.id];
  var changedBlocks = documentData === null || documentData === void 0 ? void 0 : documentData.blocks;
  if (document.updated !== (documentData === null || documentData === void 0 ? void 0 : documentData.updated)) {
    return deleteGOFBlockData(document.id);
  }
  if (changedBlocks) {
    var newBlocksData = (_c = (_b = (_a = document === null || document === void 0 ? void 0 : document.revisions) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.blocks) === null || _c === void 0 ? void 0 : _c.data;
    var isAnyBlockChanged = newBlocksData === null || newBlocksData === void 0 ? void 0 : newBlocksData.some(function (block) {
      var updated = block.updated,
        id = block.id;
      var oldBlock = changedBlocks[id];
      return oldBlock && oldBlock.updated !== updated;
    });
    if (isAnyBlockChanged) {
      return deleteGOFBlockData(document.id);
    }
    newBlocksData === null || newBlocksData === void 0 ? void 0 : newBlocksData.forEach(function (block) {
      var _a;
      var changedBlockData = (_a = changedBlocks[block.id]) === null || _a === void 0 ? void 0 : _a.data;
      var blockData = block === null || block === void 0 ? void 0 : block.data;
      if (changedBlockData) {
        blockData.tax = changedBlockData.tax;
        blockData.total = changedBlockData.total;
      }
      if ((changedBlockData === null || changedBlockData === void 0 ? void 0 : changedBlockData.sections) && (blockData === null || blockData === void 0 ? void 0 : blockData.sections)) {
        blockData.sections.forEach(function (section, index) {
          var changedSection = changedBlockData === null || changedBlockData === void 0 ? void 0 : changedBlockData.sections[index];
          if (changedSection) {
            var selected_products = changedSection.selected_products,
              changedProducts_1 = changedSection.products;
            section.products.forEach(function (product, index) {
              var _a;
              var changedProduct = changedProducts_1[index];
              if (changedProduct) {
                var quantity = changedProduct.quantity,
                  changedBundledProducts_1 = changedProduct.bundled_products;
                (_a = product === null || product === void 0 ? void 0 : product.bundled_products) === null || _a === void 0 ? void 0 : _a.forEach(function (bundledProduct, index) {
                  var changedBundledProduct = changedBundledProducts_1[index];
                  if (changedBundledProduct) {
                    bundledProduct.quantity = changedBundledProduct.quantity;
                  }
                });
                product.quantity = quantity;
              }
            });
            section.selected_products = selected_products;
          }
        });
      }
    });
  }
  if (document.payment && (documentData === null || documentData === void 0 ? void 0 : documentData.payment)) {
    document.payment = documentData === null || documentData === void 0 ? void 0 : documentData.payment;
  }
};

exports.constructInputTag = constructInputTag;
exports.convertHtmlElementToInputField = convertHtmlElementToInputField;
exports.deleteGOFBlockData = deleteGOFBlockData;
exports.getAdjustmentFeesCharged = getAdjustmentFeesCharged;
exports.getGOFDocumentData = getGOFDocumentData;
exports.getPayableAmount = getPayableAmount;
exports.getTag = getTag;
exports.isAssessmentOrQbrBlockUtil = isAssessmentOrQbrBlockUtil;
exports.isGivenDocumentOrderForm = isGivenDocumentOrderForm;
exports.setGOFBlockData = setGOFBlockData;
exports.setGOFPaymentData = setGOFPaymentData;
exports.updateGOFDocumentData = updateGOFDocumentData;
